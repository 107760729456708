@import "../../styles/modules";

.infoBubble {
    &Wrapper {
        position: fixed;
        bottom: 59px;
        right: 20px;

        @media only screen and (max-width: 768px) {
            position: initial;
            margin: 0 15px;
        }
    }
}
