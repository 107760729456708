@import "../../../styles/modules";

.switcher {
  display: flex;
  align-items: center;
  position: relative;
  background-color: rgba($c-9396a5, 0.1);
  border-radius: 18px;
  margin-left: 15px;
  margin-top: 8px;

  &:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: $c-00d1ff;
    border-radius: 18px;
    z-index: 0;
    left: 0;
    transition: left 0.4s;
  }

  &_1 {
    &:after {
      left: 50%;
    }
  }

  button {
    width: 50%;
    height: 24px;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    font-size: 10px;
    font-style: italic;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.4s;

    &.switcherActive {
      color: $c-0a0f26;
    }
  }
}
