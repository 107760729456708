@import '../../../styles/modules';

.dropdown {
    display: flex;
    flex-wrap: wrap;

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 8px solid rgba($color: $c-1e252e, $alpha: 1);
        right: 21px;
        top: -8px;
    }

    &Block {
        width: 100%;
    }

    &Item {
        position: relative;
        min-height: 1px;
        margin-bottom: 7px;

        input[type="checkbox"]:checked + label, input[type="checkbox"]:not(:checked) + label {
            text-transform: none;
            color: $white;
            white-space: nowrap;
        }
    }

    &Buttons {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 100%;
        max-width: 100%;
    }

    label {
        font-family: $font-clearsans;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: rgba($color: $white, $alpha: .8);
        margin-bottom: 8px;
        display: inline-block;
    }

    input[type="checkbox"] {
        & + label {
            font-size: 14px;
        }

        &:checked {
            background-color: $c-00d1ff;
            & + label {
                color: $white;

                &:before {
                    background-color: $c-00d1ff;
                }

                &:after {
                    color: $white;
                }
            }
        }
    }

    .checkboxSelectAll {
        &:checked,
        &:not(:checked) {
            & + label {
                color: $white;
            }
        }
    }
}
