@import "../../styles/modules";

$navbar-width: 600px;
$mobile-navbar-width: 280px;

.menu {
	&Wrapper {
		height: 100%;
		width: $navbar-width;
		background-color: $c-0a0b0b;
		position: fixed;
		top: 0;
		left: -$navbar-width;
		z-index: 11;
		transition: left 0.4s;
		box-shadow: 0px 5px 50px rgba(24, 23, 27, 0.25);
		display: flex;
		flex-direction: column;
		padding: 12px 24px 24px;
	}

	&Open {
		left: 0;
		filter: drop-shadow(-30px 0px 50px $black);
	}

	&Overlay {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 10;
		opacity: 0;
		pointer-events: none;
		cursor: pointer;
		transition: opacity 0.4s;

		&Active {
			opacity: 1;
			pointer-events: initial;
		}
	}

	&Close {
		font-family: $font-inter;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		color: rgba($color: $white, $alpha: 0.6);
		background: transparent;
		border: none;
		display: flex;
		align-items: center;
		cursor: pointer;
		outline: none;
		padding: 12px 0px;

		&Icon {
			width: 11px;
			height: 11px;
			position: relative;
			transition: opacity 0.2s ease-in-out;
			margin-right: 8px;

			&:hover {
				opacity: 0.8;
				transition: opacity 0.2s ease-in-out;
			}

			&:before {
				position: absolute;
				content: " ";
				height: 11px;
				width: 1px;
				background-color: rgba($color: $c-a4a9bf, $alpha: 0.6);
				transform: rotate(45deg);
			}

			&:after {
				position: absolute;
				content: " ";
				height: 11px;
				width: 1px;
				background-color: rgba($color: $c-a4a9bf, $alpha: 0.6);
				transform: rotate(-45deg);
			}
		}
	}
}

.heading {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
	padding: 0;
	margin-bottom: 22px;

	.share {
		font-family: $font-inter;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		text-transform: uppercase;
		color: $c-00d1ff;
	}
}

.tabsBlock {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}

.tabsAnchor {
	&::-webkit-scrollbar {
        height: 5px;
        width: 2px;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:horizontal {
        background: #a4a9bf;
        border-radius: 10px;
        cursor: pointer;
    }
    
	overflow-x: hidden;
	scrollbar-width: none;

	&Links {
		display: flex;
		height: 32px;
		flex: 0 0 32px;
		margin-bottom: 24px;

		ul {
			display: flex;
			list-style: none;
			white-space: nowrap;
			padding-left: 0;
			height: 34px;
			overflow-y: hidden;
			overflow-x: visible;
			margin: 0;
		}

		li {
			display: inline-flex;
			line-height: 16px;
			font-family: $font-clearsans;
			font-size: 12px;
			padding: 0 0 16px;
			border-bottom: 2px solid $tr;
			text-transform: uppercase;
			margin: 0 9px;
			cursor: pointer;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.active {
				border-bottom: 2px solid $c-00d1ff;

				a {
					color: $white;
				}
			}
		}

		a {
			color: $c-a4a9bf;
			text-decoration: none;
		}
	}

	.itemTab {
		margin: 0 0 24px;

		&Wrapper:not(:first-of-type) {
			margin-top: 24px;
		}
	}

	.titleTab {
		font-family: $font-playfair;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		padding: 0 0 14px;
		margin: 0 0 14px;
		border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);

		&NoBorder {
			border-bottom: none;
			margin-bottom: 0;
		}
	}

	.subtitleTab {
		font-family: $font-clearsans;
		font-size: 14px;
		line-height: 1.4;
		font-weight: 400;
		padding: 0 0 14px;
		margin: 0 0 14px;
		border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
	}

	.itemLineTab {
		padding: 10px;
	}

	.bodyTab {
		&:nth-child(odd) {
			background-color: rgba($color: $c-a4a9bf, $alpha: 0.06);
		}
	}
}

.keyTab {
	font-family: $font-clearsans;
	font-weight: normal;
	color: rgba($color: $white, $alpha: 0.6);
	font-size: 12px;
	line-height: 14px;
	margin: 0;
}

.valueTab {
	font-family: $font-inter;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;

	a {
		color: $c-fff;
		span {
			display: inline-block;
			margin-left: 10px;
		}
	}
}

.recipientInfo {
	border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
	padding-bottom: 15px;
	margin-bottom: 30px;
	position: relative;

	.icon {
		position: absolute;
		color: $c-00d1ff;
		font-size: 24px;
		width: 32px;
		height: 24px;
		top: -28px;
		background-color: $c-0a0b0b;
		padding-right: 8px;
	}

	.itemLineTab {
		padding: 5px 10px;
	}
}

.program {
	border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
	padding-bottom: 15px;
	margin-bottom: 15px;

	.itemLineTab {
		padding: 5px 10px;
	}
}

.transaction {
	margin-bottom: 15px;
	border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
	padding-bottom: 22px;

	&Title {
		font-family: $font-playfair;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 30px;
		margin: 0 0 10px;
		padding: 0;
	}

	&Info {
		font-family: $font-inter;
		font-size: 14px;
		line-height: 17px;
		margin: 0;
	}
}

.shareDesk {
	position: relative;
	display: flex;
	margin-right: 28px;
	position: relative;
	justify-content: flex-end;

	@include media-breakpoint-down(md) {
		display: none;
	}

	& > button {
		margin-left: 6px;
		margin-right: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: all 0.4s ease-in-out;
		outline: none;
		text-decoration: none;
		background-color: transparent;
		border: none;
		padding: 0px;
		background-color: $tr;

		&:hover {
			opacity: 0.7;
			transition: opacity 0.35s ease-in-out;
		}

		span {
			margin-right: 8px;
		}

		strong {
			background-color: rgba($color: $white, $alpha: 0.1);
			border-radius: 16px;
			font-size: 18px;
			line-height: 32px;
			color: $white;
			display: flex;
			width: 32px;
			height: 32px;
			align-items: center;
			justify-content: center;
		}
	}
}

.shareDropDown {
	position: absolute;
	top: 40px;
	left: 6px;
	background-color: rgba($color: $black, $alpha: 1);
	min-width: 180px;
	border-radius: 10px;
	padding: 8px 20px;
	display: none;
	z-index: 2;

	&:after {
		content: "";
		display: "block";
		height: 0;
		width: 0;
		left: 10px;
		top: -8px;
		position: absolute;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 8px solid rgba($color: $black, $alpha: 0.8);
	}

	ul {
		list-style: none;
		margin: 0;
		padding-left: 0;
	}

	li {
		border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);

		&:last-child {
			border-bottom: 0 none;
		}
	}

	span {
		font-size: 18px;
		margin-right: 10px;
	}

	button,
	strong {
		opacity: 1;
		font-family: $font-manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 12px !important;
		line-height: 18px;
		letter-spacing: 0.35px;
		padding: 12px 0 !important;
		display: block;
		white-space: nowrap;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: $transition-color;

		&:hover {
			color: rgba($color: $white, $alpha: 0.8) !important;
			transition: $transition-color;
		}
	}
}

.shareDeskToggle {
	.shareDropDown {
		display: block;
	}
}
