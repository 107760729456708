@import "../../styles/modules";

.toggler {
    padding: 4px;
    display: block;
    cursor: pointer;
    transition: opacity 0.35s ease-in-out;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    position: relative;

    &:focus {
        border: 0 none;
        outline: none;
    }

    &:hover {
        opacity: 0.7;
        transition: opacity 0.35s ease-in-out;
    }

    &Inner {
        display: block;
        top: 50%;

        &,
        &::before,
        &::after {
            background-color: $white;
            width: 24px;
            height: 2px;
            border-radius: 2px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: -8px;
            transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
        }
        &::after {
            bottom: -8px;
            transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        @media only screen and (max-width: 768px) {
            &,
            &::before,
            &::after {
                height: 1px;
                width: 20px;
            }

            &::before {
                top: -5px;
            }
            &::after {
                bottom: -5px;
            }
        }
    }

    &Box {
        width: 24px;
        height: 24px;
        display: block;
        position: relative;

        @media only screen and (max-width: 768px) {
            margin-right: 4px;
        }
    }
}
