$c-fff: #fff;
$c-000: #000;

$c-161616: #161616;
$c-00d1ff: #00d1ff;

$c-2b59c3: #2b59c3;
$c-1b1f3a: #1b1f3a;
$c-1b333f: #1b333f;
$c-a4a9bf: #a4a9bf;
$c-284063: #284063;
$c-363B55: #363b55;
$c-0a0f26: #0a0f26;
$c-9396a5: #9396a5;
$c-5a5c72: #5a5c72;
$c-07090d: #07090d;
$c-1c2736: #1c2736;
$c-191b1d: #191b1d;
$c-1e252e: #1e252e;
$c-0e1011: #0e1011;
$c-ff6e30: #ff6e30;
$c-191a1b: #191a1b;
$c-d3d3d3: #d3d3d3;
$c-1e2a31: #1e2a31;
$c-161a30: #161a30;
$c-0e1011: #0e1011;
$c-b02be3: #b02be3;
$c-18e770: #18e770;
$c-2ba8c3: #2ba8c3;
$c-00d0ff: #00d0ff;
$c-0e1620: #0e1620;
$c-23c2b5: #23c2b5;
$c-101416: #101416;
$c-1c1e1f: #1c1e1f;
$c-c4c4c4: #c4c4c4;
$c-0a0b0b: #0a0b0b;
$c-121315: #121315;
$c-1d252e: #1d252e;
$c-ff314a: #ff314a;
$c-ffca0f: #ffca0f;

$check-color-scheme-yellow: (
    c1: #3b3421,
    c2: #6c5a1d,
    c3: #9d7f18,
    c4: #cea514,
    c5: #ffca0f,
    c6: red,
);

$check-color-scheme-orange: (
    c1: #3b2228,
    c2: #6c352a,
    c3: #9d482c,
    c4: #9d482c,
    c5: #ce5b2e,
    c6: red,
);

$check-color-scheme-azure: (
    c1: #083651,
    c2: #065d7d,
    c3: #0483a8,
    c4: #02aad4,
    c5: #00d1ff,
    c6: red,
);

$check-color-scheme-light-blue: (
    c1: #2d3d49,
    c2: #416076,
    c3: #5482a4,
    c4: #68a5d1,
    c5: #7cc8ff,
    c6: red,
);

$check-color-scheme-blue: (
    c1: #111e45,
    c2: #172d65,
    c3: #1e3b84,
    c4: #244aa3,
    c5: #2b59c3,
    c6: red,
);

$check-color-scheme-green: (
    c1: #0d3a35,
    c2: #106544,
    c3: #129052,
    c4: #15bb61,
    c5: #18e670,
    c6: red,
);

$check-color-scheme-light-green: (
    c1: #374817,
    c2: #547610,
    c3: #72a30b,
    c4: #8fd105,
    c5: #adff00,
    c6: red,
);

$check-color-scheme-olive: (
    c1: #0f3343,
    c2: #15575f,
    c3: #197a7c,
    c4: #1e9e98,
    c5: #23c2b5,
    c6: red,
);

$check-color-scheme-purple: (
    c1: #2b154c,
    c2: #4c1a72,
    c3: #6d2097,
    c4: #8e25bd,
    c5: #af2be3,
    c6: red,
);

$check-color-scheme-light-purple: (
    c1: #2f2e49,
    c2: #464176,
    c3: #5c55a4,
    c4: #7368d1,
    c5: #897cff,
    c6: red,
);

$check-color-scheme-light-rose: (
    c1: #472728,
    c2: #753535,
    c3: #a34243,
    c4: #d15050,
    c5: #ff5d5d,
    c6: red,
);
$check-color-scheme-light-rose1: (
    c1: #47373E,
    c2: #755560,
    c3: #A37283,
    c4: #D190A5,
    c5: #FFADC8,
    c6: #FFADC8,
);
$check-color-scheme-light-rose2: (
    c1: #43332E,
    c2: #6C4C41,
    c3: #966554,
    c4: #BF7E67,
    c5: #E9977A,
    c6: #E9977A,
);

$check-color-scheme-oct21: (
    c1: #2F2A23,
    c2: #453A2B,
    c3: #5A4B33,
    c4: #866B43,
    c5: #866B43,
);
$check-color-scheme-jan22: (
    c1: #474434,
    c2: #756F4D,
    c3: #A39965,
    c4: #D1C37E,
    c5: #FFEE97,
);
$check-color-scheme-apr22: (
    c1: #363E2E,
    c2: #536241,
    c3: #708755,
    c4: #8DAB68,
    c5: #AACF7B,
);

$check-color-scheme-rose: (
    c1: #3b1641,
    c2: #6c1d5c,
    c3: #9d2376,
    c4: #ce2a91,
    c5: #ff31ac,
    c6: red,
);

$check-color-scheme-rose: (
    c1: #3b1641,
    c2: #6c1d5c,
    c3: #9d2376,
    c4: #ce2a91,
    c5: #ff31ac,
    c6: red,
);

$check-color-schemes: (
    "azure": $check-color-scheme-azure,
    "blue": $check-color-scheme-blue,
    "light-blue": $check-color-scheme-light-blue,
    "yellow": $check-color-scheme-yellow,
    "orange": $check-color-scheme-orange,
    "green": $check-color-scheme-green,
    "light-green": $check-color-scheme-light-green,
    "olive": $check-color-scheme-olive,
    "purple": $check-color-scheme-purple,
    "light-purple": $check-color-scheme-light-purple,
    "rose": $check-color-scheme-rose,
    "light-rose": $check-color-scheme-light-rose,
    "light-rose1": $check-color-scheme-light-rose1,
    "light-rose2": $check-color-scheme-light-rose2,
    "oct21": $check-color-scheme-oct21,
    "jan22": $check-color-scheme-jan22,
    "apr22": $check-color-scheme-apr22,
);

$mpp-chart-colors: (
    c1: #18e770,
    c2: #ff6e30,
    c3: #b02be3,
    c4: #2b59c3,
    c5: #00d1ff,
);

$landing-list-colors: (
    c1: #00d1ff,
    c2: #18e770,
    c3: #ff6e30,
    c4: #2b59c3,
    c5: #23c2b5,
    c6: #b02be3,
);

$landing-legend-colors: (
    c1: #f2fdff,
    c2: #ccf6ff,
    c3: #99edff,
    c4: #66e3ff,
    c5: #00d1ff,
    c6: #0397be,
    c7: #074967,
    c8: #083651,
);

$white: $c-fff;
$black: $c-000;
$primary: $c-2b59c3;

$tr: transparent;

$body-bg: $c-191a1b;
$body-color: $white;

$font-inter: "Inter", sans-serif;
$font-manrope: "Manrope", sans-serif;
$font-playfair: "Playfair Display", serif;
$font-clearsans: "Clear Sans", sans-serif;
$font-pogo: "POGO";

$transition-color: color 0.35s ease-in-out;
$transition-bg: background-color 0.35s ease-in-out;
$transition-border: border-color 0.35s ease-in-out;

$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-row-columns: 6;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    3xl: 1600px,
    //4xl: 1900px,
    //4k: 3800px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1340px,
    3xl: 1540px,
    //4xl: 1840px,
    //4k: 3780px,
);
