@import "../../styles/modules";

.faqItem {
  border: 1px solid $c-1b1f3a;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include media-breakpoint-down(md) {
    margin-left: 10px;
    margin-right: 10px;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }

  &Wrapper {
    width: 100%;
  }

  &Question {
    font-family: $font-playfair;
    font-size: 22px;
    line-height: 30px;
    cursor: pointer;
    color: $c-a4a9bf;
    transition: color 0.35s;
    position: relative;

    @include media-breakpoint-down(md) {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      font-family: $font-clearsans;
    }

    &:hover {
      color: rgba($white, 0.8);
    }

    &Spacer {
      padding: 23px 20% 23px 43px;

      @include media-breakpoint-down(md) {
        padding: 15px 20px;
      }
    }
  }

  &Open {
    .faqItemToggler {
      color: $white;

      &Icon {
        font-weight: normal;
        &:before {
          content: "-";
        }
      }
    }

    .faqItemQuestion {
      color: $white;
    }

    .faqItemAnswer {
      max-height: 350px;
      padding-bottom: 22px;
      opacity: 1;

      @include media-breakpoint-down(md) {
        max-height: 870px;
        transition: max-height 0.35s ease-in-out, padding 0.35s ease-in-out, opacity 0.35s ease-in-out;
      }
    }
    .faqItemAnswerLong {
      max-height: 870px;

      @include media-breakpoint-down(md) {
        max-height: 2000px;
      }
    }
  }

  &Toggler {
    width: 78px;
    min-width: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c-a4a9bf;
    background-color: $c-1b1f3a;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      min-width: 68px;
    }

    &Icon {
      position: absolute;
      pointer-events: none;
      right: -78px;
      width: 78px;
      top: 0;
      height: 100%;
      font-size: 24px;
      font-family: $font-playfair;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(md) {
        min-width: 68px;
        width: 68px;
        right: -68px;
        font-size: 40px;
        font-weight: normal;
      }

      &:before {
        content: "+";
      }
    }
  }

  &Answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out, padding 0.35s ease-in-out, opacity 0.35s ease-in-out;
    padding: 0 20% 0 43px;
    opacity: 0;
    white-space: pre-line;
    font-family: $font-clearsans;
    color: $c-a4a9bf;
    font-size: 18px;
    line-height: 30px;

    @include media-breakpoint-down(md) {
      padding: 0 20px 0 20px;
      font-size: 16px;
      line-height: 26px;
      transition: none;

      ul {
        padding-left: 20px;
      }
    }

    a {
      color: $c-00d1ff;
      &:hover {
        text-decoration: none;
      }
    }

    &Long {
      max-height: 0;
    }
  }
}
