@import "../../styles/modules";

.popover {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &Button {
        padding: 0;
        border: 0 none;
        font-size: 14px;
        line-height: 32px;
        color: $c-a4a9bf;
        white-space: nowrap;
        background-color: $tr;
        font-family: $font-clearsans;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        span {
            display: inline-flex;

            &:first-child {
                margin-right: 5px;
            }

            &:last-child {
                margin-left: 10px;
                font-size: 18px;
            }
        }

        &SmallIcon {
            font-size: 14px !important;
            color: $white;
        }

        &NoSpace {
            margin-left: 1px !important;
        }

        &White {
            color: $white;
        }
    }

    &Content {
        background-color: rgba($color: $c-1e252e, $alpha: 1);
        border-radius: 10px;
        padding: 20px 15px 20px;
        position: absolute;
        width: 340px;
        top: 0;
        left: 0;
        margin-top: 46px;
        z-index: 3;

        &Auto {
            width: auto;
            padding: 16px 16px 1px;
            margin-top: 36px;
            border-radius: 5px;
            left: unset;
            right: -19px;
        }

        &Title {
            position: relative;
            width: 100%;
            min-height: 1px;
            margin-bottom: 16px;
            flex: 0 0 100%;
            max-width: 100%;
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            text-transform: uppercase;
            color: rgba($color: $white, $alpha: 0.75);

            span {
                position: absolute;
                right: -8px;
                top: -8px;
                font-size: 16px;
                color: $white;
                cursor: pointer;
                padding: 8px;
            }
        }
    }
}

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    margin-left: 5px;
}
