@import "../../styles/variables";
@import "node_modules/bootstrap/scss/mixins";

path {
	pointer-events: all;
}

.usa-container {
	width: calc(100% - 30px);
	max-width: calc(100vh - 50px);
	margin: 0 auto;

	.us-state-map {
		width: 100%;
		height: auto;
		margin-top: 1.2%;
		margin-left: 1.1%;

		path {
			stroke: $black;
			stroke-width: 0.3px;
		}

		.DC {
			path {
				stroke-width: 1px;
			}
		}

		.DC1,
		.DC2 {
			display: none;
		}

		path:hover {
			cursor: pointer;
		}
	}
}

.custom-tooltip {
	pointer-events: none;
	img {
		transform: scale(1.7);
	}
}

.circle-container {
	position: relative;
	text-align: center;
	color: $black;
	animation: rise 0.4s ease;

	div {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $font-clearsans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		line-height: 1;
		color: $c-1e2a31;
		background: $tr;
	}
}

.control {
	position: absolute;
	bottom: 21px;
	right: 15px;
	z-index: 1;
}

.btn-refresh {
	position: relative;
	background-color: $c-1d252e;
	border-radius: 50%;
	border: 1px solid $c-191a1b;
	color: white;
	font-size: 20px;
	width: 38px;
	height: 38px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	cursor: pointer;

	span {
		margin-top: 2px;
	}

	&:focus {
		outline: 0 none;
	}
}

.zoom {
	position: relative;
	background-color: $c-1d252e;
	border-radius: 19px;
	border: 1px solid $c-191a1b;

	&:before {
		width: 18px;
		height: 1px;
		background-color: rgba($color: $c-c4c4c4, $alpha: 0.1);
		position: absolute;
		top: 50%;
		left: calc(50% - 9px);
		content: "";
	}

	&-in,
	&-out {
		width: 36px;
		height: 36px;
		display: block;
		border: 0 none;
		position: relative;
		cursor: pointer;
		background-color: $tr;
		border-radius: 18px;

		&:focus {
			outline: none;
		}

		&:before {
			content: "";
			width: 12px;
			height: 2px;
			border-radius: 2px;
			background-color: $white;
			position: absolute;
			top: calc(50% - 1px);
			left: calc(50% - 6px);
			transition: background-color 0.35s ease-in-out;
		}

		&:hover {
			&:before,
			&:after {
				background-color: $c-00d1ff;
				transition: background-color 0.35s ease-in-out;
			}
		}
	}

	&-in {
		margin-bottom: 5px;

		&:after {
			content: "";
			height: 12px;
			width: 2px;
			border-radius: 2px;
			background-color: $white;
			position: absolute;
			left: calc(50% - 1px);
			top: calc(50% - 6px);
			transition: background-color 0.35s ease-in-out;
		}
	}
}

.search-wrapper {
	border-radius: 25px;
	display: flex;
	width: 480px;
	margin: 0 auto 10px;
	position: relative;
	flex-direction: column;
	align-items: flex-end;

	@include media-breakpoint-down(xl) {
		width: 360px;
	}
	@media only screen and (max-height: 768px) {
		width: 360px;
	}

	& > div {
		width: 480px;
		z-index: 1;

		@include media-breakpoint-down(xl) {
			width: 360px;
		}
		@media only screen and (max-height: 768px) {
			width: 360px;
		}

		& > div {
			&:first-child {
				background-color: rgba($color: $black, $alpha: 1);
				padding-right: 20px;
			}
		}
	}

	input {
		padding-left: 20px;
		text-transform: uppercase;

		@include media-breakpoint-down(xl) {
			min-height: 32px;
		}
		@media only screen and (max-height: 768px) {
			min-height: 32px;
		}
	}

	.ribbon {
		min-width: 280px;
		width: auto;
		height: 24px;
		font-family: $font-clearsans;
		font-size: 12px;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: $c-00d1ff;
		padding-left: 45px;
		padding-right: 20px;
		background-color: rgba($color: $c-00d1ff, $alpha: 0.2);
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
		position: absolute;
		margin-top: 0;
		z-index: 0;

		@include media-breakpoint-up(xl) {
			top: 8px;
			left: 448px;
			min-width: 280px;
		}

		@include media-breakpoint-down(xl) {
			top: 5px;
			left: 330px;
		}
		@media only screen and (max-height: 768px) {
			top: 5px;
			left: 330px;
		}

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-right: 9px solid $c-191a1b;
			position: absolute;
			right: 0;
		}
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes rise {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
