@import "../../styles/modules";

.methodology {
    font-family: $font-clearsans;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    padding-top: 70px;

    @include media-breakpoint-down(sm) {
        padding: 9px 22px;
    }

    &Title {
        font-family: $font-playfair;
        font-size: 56px;
        font-weight: normal;
        line-height: 66px;
        letter-spacing: -0.5px;
        margin: 0;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.2);
        position: sticky;
        top: 0;

        @include media-breakpoint-down(sm) {
            font-size: 32px;
            line-height: 40px;
            border-bottom: 0;
            padding-bottom: 0;
            position: relative;
        }
    }

    &HeadingFirst {
        font-family: $font-playfair;
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    &HeadingL1 {
        font-family: $font-playfair;
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        color: $c-00d1ff;
        margin: 0;
        padding: 30px 0px 20px;
    }

    &HeadingL2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: -20px;
        padding-top: 20px;
    }

    p {
        margin-top: 0;
        margin-bottom: 30px;

        &.grayColor {
            color: $c-a4a9bf;
        }

        &.fontSm {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 20px;
        }

        & + .methodologyHeadingL1 {
            border-top: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.2);
            margin-top: 30px;
        }

        & + ul {
            margin-top: -10px;
        }
    }

    a {
        color: $c-00d1ff;

        &:hover {
            text-decoration: none;
        }
    }

    &NegativeRow {
        margin-top: -10px;
    }
}

.tableBgBordered {
    width: 100%;
    margin: 10px 0 20px;
    padding: 0;
    border-spacing: 4px;

    th {
        text-align: left;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.35px;
        color: $c-a4a9bf;
        padding: 10px 20px;
        background-color: $c-161a30;
    }

    td {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 26px;
        background-color: $c-161a30;
    }
}

.anchorMenu {
    position: fixed;
    bottom: 76px;
    margin-bottom: 0;
    padding-left: 0;

    @include media-breakpoint-down(sm) {
        position: relative;
        bottom: unset;
    }

    ul {
        list-style: none;

        li {
            text-decoration: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &Active {
        color: $c-00d1ff;
        text-decoration: underline;
    }
}

.level1 {
    margin-bottom: 0;
    border-left: 1px solid transparent;
    padding-left: 19px;
    position: relative;

    @include media-breakpoint-down(sm) {
        border-left: 1px solid $c-1b1f3a;
        border-bottom: none;
        line-height: 22px;
        margin-bottom: 33px;
        padding-bottom: 4px;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid $c-1b1f3a;
            left: 0;
            bottom: -12px;
        }
    }

    & > li {
        font-weight: bold;
        margin-bottom: 6px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        & > a {
            color: $c-00d1ff;
            text-decoration: none;

            @include media-breakpoint-down(sm) {
                color: $c-a4a9bf;
            }
        }
        @include media-breakpoint-down(sm) {
            &:first-of-type {
                & > a {
                    color: $c-00d1ff;
                }

                &:before {
                    content: "";
                    position: absolute;
                    height: calc(100% + 10px);
                    width: 1px;
                    border-left: 1px solid $c-00d1ff;
                    left: -20px;
                    top: -5px;
                }
            }
        }
    }
}

.level2 {
    margin-bottom: 0;
    padding-left: 10px;
    border-left: 1px solid $c-a4a9bf;
    margin-top: 6px;
    margin-left: 20px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 1em;
        height: calc(100% - 1em);
        width: 0px;
        border-right: 1px solid $c-00d1ff;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }

    & > li {
        font-size: 16px;
        line-height: 26px;
        font-weight: normal;
        margin-bottom: 6px;
        color: $c-a4a9bf;
        text-decoration: none;
        transition: color 0.2s;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }

    .anchorMenuActive,
    .anchorMenuActive:hover {
        color: $c-00d1ff;
    }
}

.customUl {
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px;
    margin-top: 0;

    &Gray {
        color: $c-a4a9bf;
    }

    li {
        position: relative;
        padding-left: 30px;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;

        &:after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $c-00d1ff;
            position: absolute;
            border-radius: 50%;
            top: 11px;
            left: 0;
        }
    }

    & + .methodologyHeadingL1 {
        border-top: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.2);
        margin-top: 30px;
    }
}

.gradientBottom {
    position: relative;
    padding-bottom: 76px;

    &:before {
        width: 100%;
        position: fixed;
        bottom: 0;
        pointer-events: none;

        height: 190px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $c-0a0f26 100%);

        content: "";
        z-index: 1;

        @include media-breakpoint-down(sm) {
            height: 30px;
        }
    }
}
