
@import '../../../styles/modules';

.filterTop {
    display: flex;
    min-height: 54px;
    align-items: flex-start;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba($color: $white, $alpha: .2);
    margin-bottom: 10px;

    @include media-breakpoint-down(xl) {
        flex-direction: column;
    }

    &Search {
        display: flex;
        @include media-breakpoint-down(xl) {
            min-height: 50px;
        }
    }

    &Filter{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include media-breakpoint-down(xl) {
            margin-bottom: 10px;
        }
    }

    &Column {

        @include media-breakpoint-down(xxl) {
            flex-direction: column;
        }
    }
}

.industry,
.moreFilter {
    position: relative;

    &:after {
        width: 1px;
        height: 20px;
        content: '';
        position: absolute;
        top: calc( 50% - 10px);
        left: 0;
        background-color: rgba($color: $white, $alpha: .2);
    }
}

.industryNoBorder {
    &:after {
        display: none;
    }
}

.moreFilter {
    padding-left: 18px;
    padding-right: 20px;
    margin-right: 10px;
}

.recipientSearch {
    padding: 0;
    min-width: 150px;
    margin-left: 18px;
}

.typeOfLoan {
    padding: 0 15px 0 20px;
    min-width: 140px;
    position: relative;
}
.industry {
    padding: 0 15px 0 10px;
    min-width:  145px;
}

.badges {
    position: absolute;
    top:calc( 50% - 7px);
    right: 0;
}

.clearAllFilters {
    border: 0 none;
    background-color: $tr;
    color: rgba($color: $white, $alpha: .75);
    font-family: $font-clearsans;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 15px;
    padding:4px 0 5px 13px;
    cursor: pointer;

    &:focus {
        outline: 0 none;
    }

    &:before {
        background:$tr url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDcgMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02IDFMMSAxMUw2IDIxIiBzdHJva2U9IndoaXRlIi8+Cjwvc3ZnPgo=") 0 0 no-repeat;
        width: 5px;
        height: 20px;
        position: absolute;
        content: '';
        opacity: .2;
        top: calc(50% - 11px);
        left: 0;
    }

    span {
        margin-right: 8px;
        margin-top: 1px;
    }
}

.switchContainer {
    position: relative;
    font-size: 12px;
    line-height: 14px;
    font-family: $font-clearsans;
    color: $c-a4a9bf;
    margin-right: 18px;
    margin-top: 3px;

    &:before{
        background-color: rgba($color: $c-00d1ff, $alpha: .3);
        height: 34px;
        width: 19px;
        border-radius: 17px;
        vertical-align: middle;
        transition-duration: .4s;
        transition-property: background-color, box-shadow;
        cursor: pointer;
        margin-bottom: -2px;
        content: '';
        position:absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    &:after {
        background-color: $c-00d1ff;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        transform: translate3d(0, 0, 0);
        transition-timing-function: cubic-bezier(1,0,0,1);
        transition-duration: 0.4s;
        transition-property: transform, background-color;
        content: '';
        position:absolute;
        top: 5px;
        left: 5px;
        pointer-events: none;
    }

    span {
        transition: $transition-color;

        &:first-child {
            color: $white;
            transition: $transition-color;
        }
    }

    label{
        min-height: 32px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding-left: 24px;


        @include media-breakpoint-up(md) {
            justify-content: center;
        }
    }

    &.isContracts {

        &:after {
            transform: translate3d(0, 15px, 0);
            transition-timing-function: cubic-bezier(1,0,0,1);
            transition-duration: 0.4s;
            transition-property: transform, background-color;
            pointer-events: none;
        }

        span {
            &:first-child {
                color: $c-a4a9bf;
                transition: $transition-color;
            }

            &:last-child {
                color: $white;
                transition: $transition-color;
            }
        }
    }
}

.disabled {
    color: green;
    pointer-events: none;
    div {
        color: #5A5D6C !important;
    }

    button {
        color: #5A5D6C!important;
    }

    span {
        color: #5A5D6C!important;
    }
}
