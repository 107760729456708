@import '../../styles/modules';

.toolPage {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-height: calc(100vh - 165px);

    &Left {
        display: flex;
        padding-top: 79px;
        justify-content: flex-end;
        align-items: flex-start;

        ul {
            list-style: none;
            margin: 0;
            padding-left: 0;
            border-top: 1px solid rgba($color: $c-a4a9bf, $alpha: .2);

            li {
                border-bottom:1px solid rgba($color: $c-a4a9bf, $alpha: .2);
                height: auto !important;
            }

            strong {
                display: block;
                padding: 20px 0;
                color: $c-a4a9bf;
                font-family: $font-clearsans;
                font-weight: normal;
                line-height: 26px;
                text-decoration: none;
                cursor: pointer;
            }

            .active {
                strong {
                    color: $white;
                }
                span {
                    color: $c-00d1ff;
                }
            }

            span {
                color: rgba($color: $c-a4a9bf, $alpha: .5);
                display: inline-block;
                margin-right: 15px;
            }
        }

        &:after {
            height: calc(100%);
            width: 1px;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($color: $c-a4a9bf, $alpha: .2);
        }
    }

    .Menu {
        min-width: 235px;
        position: sticky;
        top: 0;

        &Title {
            font-family: $font-playfair;
            font-weight: normal;
            font-size: 32px;
            line-height: 40px;
            margin: 0 0 15px;
        }
    }
}

.toolPageContent {
    &Inner {
        padding-top: 0px;
        max-width: 896px;
        position: relative;
    }
}

.imgBlock {
    box-shadow: 0px 5px 50px rgba($color: $c-00d0ff, $alpha: .11);
    max-width: 800px;
    margin-bottom: 40px;


    img {
        display: block;
        border-radius: 5px;
    }
}

.title {
    font-family: $font-playfair;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 40px;
}

.slide {
    padding-left: 48px !important;
    padding-right: 48px !important;
    margin-bottom: 40px;

    &Caption {
        display: flex;
    }

    &Info {
        padding-left: 25px;
        padding-right: 107px;
    }

    &Number {
        font-family: $font-playfair;
        font-style: italic;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: rgba($color: $c-a4a9bf, $alpha: .5);
        flex: 0 0 55px;
        max-width: 55px;
        text-transform: uppercase;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            height: 100%;
            right: 0;
            top: 0;
            position: absolute;
            background-color: rgba($color: $c-a4a9bf, $alpha: .2);
        }
    }

    &Title {
        font-family: $font-playfair;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 16px;
    }

    &Text {
        font-family: $font-clearsans;
        font-size: 16px;
        line-height: 26px;
        color: $c-a4a9bf;
    }
}

.navTool {
    position: absolute;
    bottom: 73px;
    right: 48px;
    z-index: 1;

    &Left,
    &Right {
        width: 36px;
        height: 36px;
        font-size: 24px;
        margin: 5px;
        color: $c-a4a9bf;
        border: 1px solid $c-a4a9bf;
        border-radius: 18px;
        background-color: $tr;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &.disable {
            opacity: .5;
        }

        span {
            margin-left: -1px;
        }
    }
}
