@import "../../../styles/modules";

.mppChart {
  &Bar {
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    &Element {
      height: 24px;
      box-shadow: inset 0px -16px 32px rgba(0, 0, 0, 0.4);
      margin-right: 2px;
      transition: width 0.3s;

      &:last-of-type {
        margin-right: 0;
      }

      @each $name, $color in $mpp-chart-colors {
        $i: index($mpp-chart-colors, $name $color);
        &:nth-of-type(#{$i}) {
          background-color: $color;
        }
      }
    }
  }

  &Legend {
    display: flex;
    margin-top: 16px;
    margin-bottom: 2px;

    &Item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 25px;

      @each $name, $color in $mpp-chart-colors {
        $i: index($mpp-chart-colors, $name $color);
        &:nth-of-type(#{$i}) {
          .mppChartLegendItemVal {
            &:before {
              background-color: $color;
            }
          }
        }
      }

      span {
        font-size: 10px;
        color: $c-a4a9bf;
        margin-top: 10px;
      }

      &Val {
        display: flex;
        align-items: center;
        font-size: 12px;

        &:before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: block;
          margin-right: 5px;
        }
      }
    }
  }
}
