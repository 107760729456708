@import '../../styles/modules';

.detailedInfo {
    background-color: rgba($color: $c-0e1011, $alpha: .8);
    padding: 0px 15px 33px;
    margin-bottom: 16px;
    border-radius: 4px;

    &Heading {
        border-bottom: 1px solid rgba($color: $white, $alpha: .2);
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        margin-bottom: 20px;


        button {
            background-color: $tr;
            margin: 0;
            padding: 0;
            border: 0 none;
            color: rgba($color: $white, $alpha: .6);
            font-family: $font-inter;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            cursor: pointer;

            span {
                margin-right: 5px;
            }
        }
    }
}

.tableItem {
    &Top {
        td {
            padding-bottom: 10px;
        }

        .total {
            font-family: $font-playfair;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
        }

        td:not(.total) {
            font-family: $font-inter;
            font-size: 12px;
            line-height: 15px;
            color: rgba($color: $white, $alpha: .6);
        }
    }

    &Content {
        align-items: center;
        font-family: $font-inter;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $white;

        td {
            padding-bottom: 18px;
        }

        span {
            display: inline-block;
            margin-left: 40px;
            margin-right: 16px;
            font-size: 14px;
            opacity: .6;
        }
    }

    &Footer {
        font-family: $font-inter;
        font-size: 12px;
        line-height: 15px;
        color: rgba($color: $white, $alpha: .6);
    }
}

.mapSearchContainer{
    position: absolute;
    top: 35px;
}


.tabsAnchor {
    border-radius: 4px;
    background-color: rgba($color: $c-0e1011, $alpha: .8);
    padding-top: 14px;
    overflow-y: scroll;
    height: 400px;
    margin-bottom: 16px;

    &Links {
        display: flex;
        overflow: hidden;
        height: 36px;
        margin-bottom: 11px;

        ul {
            display: flex;
            list-style: none;
            white-space: nowrap;
            padding-left: 0;
            height: 60px;
            overflow-y: hidden;
            overflow-x: scroll;
            margin:0;
        }

        li {
            display: inline-flex;
            line-height: 36px;
            font-family: $font-clearsans;
            font-size: 14px;
            padding: 0 8px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            &.active {
                a {
                    color: $white;
                }
            }
        }

        a {
            color: $c-a4a9bf;
            text-decoration: none;
        }
    }

    .title {
        font-family: $font-playfair;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        padding: 0 15px 13px;
        border-bottom: 1px solid rgba($color: $white, $alpha: .2);
        margin-bottom: 20px;
    }

    .item {
        margin-bottom: 24px;
    }

    .key {
        font-family: $font-clearsans;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.35px;
        color: rgba($color: $white, $alpha: .6);
        margin-top: 0;
        margin-bottom: 8px;
    }

    .value {
        font-family: $font-inter;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }

    .body {
        padding: 0px 15px 31px;
    }
}


.similar {

    &Items {
        .title {
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h3 {
            margin: 0;
            padding: 0;
            font-family: $font-clearsans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
        }

        .select {
            width: 90px;
        }
    }


    &List {
        .item {
            border-radius: 4px;
            background-color: rgba($color: $c-0e1011, $alpha: .8);
            padding: 11px 12px 10px;
            margin-bottom: 10px;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font-inter;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            color:rgba($color: $white, $alpha: .6);
            margin-bottom: 10px;
        }

        .total {
            font-family: $font-playfair;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            margin-top: 0;
            margin-bottom: 10px;
        }

        .name {
            margin: 0 0 5px;
            padding:0;
            font-size: 12px;
            line-height: 15px;
            font-family: $font-inter;
        }

        p {
            color: rgba($color: $white, $alpha: .6);
            font-family: $font-inter;
            line-height: 15px;
            font-size: 12px;
            margin:0;
        }
    }
}
