.heading-title {
	font-family: $font-playfair;
	font-weight: bold;
	font-size: 36px;
	line-height: 48px;
	text-align: center;
	margin: 24px 0 20px;

	@include media-breakpoint-down(xl) {
		font-size: 26px;
    line-height: 20px;
    letter-spacing: 1px;
	}

	@media only screen and (max-height: 768px) {
		font-size: 26px;
    line-height: 20px;
    letter-spacing: 1px;
	}
}

.heading-text {
	font-family: $font-playfair;
	font-weight: normal;
	font-size: 56px;
	line-height: 66px;
	letter-spacing: -0.5px;

	span {
		color: $c-00d1ff;
	}
}
