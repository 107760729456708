@import "../../../styles/modules";

$navbar-width: 360px;
$mobile-navbar-width: 280px;

.menu {
    &Wrapper {
        height: 100%;
        width: $navbar-width;
        background-color: $c-1b1f3a;
        position: fixed;
        top: 0;
        right: -$navbar-width;
        z-index: 10;
        transition: right 0.4s;
        box-shadow: 0px 5px 50px rgba(24, 23, 27, 0.25);
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 768px) {
            width: $mobile-navbar-width;
            right: -$mobile-navbar-width;
        }
    }

    &Open {
        right: 0;
        filter: drop-shadow(-30px 0px 50px $black);
    }

    &Overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
        transition: opacity 0.4s;

        &Active {
            opacity: 1;
            pointer-events: initial;
        }
    }

    &Heading {
        height: 80px;
        border-bottom: 1.5px solid $c-363B55;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 24px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            right: 31px;
            height: 8px;
            width: 8px;
            border: 1.5px solid $c-363B55;
            border-right: none;
            border-bottom: none;
            background: $c-1b1f3a;
            transform: rotate(45deg) skew(10deg, 10deg);
        }
    }

    &Close {
        width: 24px;
        height: 24px;
        background: transparent;
        border: none;
        position: relative;
        transition: opacity 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        &:before {
            position: absolute;
            content: " ";
            height: 24px;
            width: 2px;
            background-color: $c-a4a9bf;
            transform: rotate(45deg);
        }

        &:after {
            position: absolute;
            content: " ";
            height: 24px;
            width: 2px;
            background-color: $c-a4a9bf;
            transform: rotate(-45deg);
        }
    }

    &Body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    &Main {
        padding: 0;
        margin-top: 26px;
    }

    &Secondary {
        position: relative;

        &:before {
            content: "";
            width: calc(100% - 26px * 2);
            height: 1px;
            background: $c-363B55;
            position: absolute;
            top: -1px;
            left: 26px;
        }
    }

    &NavItem {
        font-size: 18px;
        list-style: none;
        transition: background-color 0.15s;

        a {
            color: $c-a4a9bf;
            line-height: 30px;
            text-decoration: none;
            font-size: 18px;
            display: flex;
            align-items: center;
            padding: 15px 28px;

            .icon {
                margin-right: 20px;
            }
        }

        &:hover {
            background-color: $c-0a0f26;

            a {
                color: $white;

                .icon {
                    color: $c-00d1ff;
                }
            }
        }
    }

    &ModeSwitch {
        font-size: 18px;
        list-style: none;
        padding: 15px 28px;
        display: flex;
        margin-top: 14px;

        a {
            color: $c-a4a9bf;
            text-decoration: none;
            padding: 8px 20px;
            border: 1px solid $c-0a0f26;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
            display: inline-flex;
            align-items: center;
            transition: background-color 0.25s;

            &:first-of-type {
                border-radius: 20px 0px 0px 20px;

                &:hover {
                    background: $c-0a0f26;
                    color: $white;
                }
            }
            &:last-of-type {
                border-radius: 0px 20px 20px 0px;

                &:hover {
                    background: $white;
                    color: $c-0a0f26;
                }
            }
        }

        &Active {
            background: $c-0a0f26;
            color: $white !important;
        }

        .icon {
            font-size: 18px;
            margin-right: 8px;
        }
    }

    &Footer {
        margin: 0 30px;
        border-top: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
        padding-top: 25px;

        h3 {
            font-family: $font-playfair;
            font-size: 22px;
            line-height: 30px;
            font-weight: normal;
            color: $white;
            margin-top: 0;
            margin-bottom: 8px;
        }
        p {
            font-family: $font-clearsans;
            font-size: 16px;
            line-height: 26px;
            color: $c-a4a9bf;
            margin: 0 0 30px;

            a {
                color: $c-00d1ff;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.icon {
    font-size: 20px;
}
