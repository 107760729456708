.colorBar {
    display: none;

    @media only screen and (max-width: 768px) {
        position: absolute;
        height: 100%;
        width: 2px;
        left: 0;
        top: 0;
        display: block;
    }
}
