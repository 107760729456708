@import "../../styles/modules";

.aboutPage {
    font-family: $font-playfair;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px;
    padding-top: 70px;
    min-height: calc(100vh - 165px);
    background: $tr url("/img/pogo-bg.svg") 0 100% no-repeat;

    @include media-breakpoint-down(lg) {
        background-size: 350px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 9px;
        background: none;
        padding-left: 22px;
        padding-right: 22px;

    }

    &Title {
        font-size: 56px;
        font-weight: normal;
        line-height: 66px;
        letter-spacing: -0.5px;
        margin: 0;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.2);
        position: sticky;
        top: 0;

        @include media-breakpoint-down(sm) {
            font-size: 32px;
            line-height: 40px;
            padding-bottom: 12px;
            margin-bottom: 14px;
            position: relative;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 30px;
    }

    a {
        color: $c-00d1ff;

        &:hover {
            text-decoration: none;
        }
    }

    &CustomDl {
        dt {
            margin-top: 0;
            margin-bottom: 10px;
        }

        dd {
            font-family: $font-clearsans;
            font-size: 18px;
            line-height: 30px;
            color: $c-a4a9bf;
            margin-left: 0;
            padding-bottom: 30px;
            border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.2);
            margin-bottom: 30px;

            &:last-child {
                border-bottom: 0 none;
            }
        }
    }

    &Quote {
        font-style: italic;
        color: $c-00d1ff;
        padding-bottom: 30px;
        border-bottom: 4px solid $c-00d1ff;
    }
}
