@import "../../styles/modules";

$sidebar-width: 360px;

.sidebar {
    &Wrapper {
        height: 100vh;
        width: $sidebar-width;
        background-color: $c-1e252e;
        position: fixed;
        top: 0;
        right: -$sidebar-width;
        z-index: 12;
        transition: right 0.4s;
        box-shadow: 0px 5px 50px rgba(24, 23, 27, 0.25);
        display: flex;
        flex-direction: column;
        overflow-x: auto;
    }

    &Open {
        right: 0;
        filter: drop-shadow(-30px 0px 50px $black);
    }

    &Overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 11;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
        transition: opacity 0.4s;

        &Active {
            opacity: 1;
            pointer-events: initial;
        }
    }
}
