@import "node_modules/bootstrap/scss/mixins";

*, ::after, ::before {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $body-bg;
    font-family: $font-inter;
    color: $body-color;
    position: relative;
}

.bg-circles {
    background-image: url('/img/bottom-blur-circle.svg'), url('/img/top-blur-circle.svg');
    background-repeat: no-repeat;
    background-position: right calc(50% + 526px) bottom -158px, right 50% top -252px;
    background-size: 793px, 693px;
    background-attachment: fixed;
    padding-top: 80px;
    position: relative;
    background-color: $body-bg;
    min-height: 100vh;
    overflow: hidden;
}

.bg-overwflow {
    height: 100vh;
    overflow: hidden;
}

.bg-industry-overviews {
    display: flex;
    flex-direction: column;
    padding-top: 95px;
    background-color: $c-1c1e1f;
    background-image: url('/img/bottom-blur-circle.svg'), url('/img/bg-industry-overviews.png');
    background-repeat: no-repeat;
    background-position: right 110% top 50%, left 50% top 0;
    background-size: 793px,  100% auto;
    background-attachment: fixed, initial;
}

.bg-industry-detailed {
    background-color: $c-1c1e1f;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding-top: 95px;

    footer {
        z-index: 1;
    }
}

.bg-industry-detailed-scroll {
    background-color: $c-1c1e1f;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding-top: 95px;
}

.bg-liberty {
    background-image: url('/img/bottom-blur-circle.svg'), url('/img/top-blur-circle.svg'), url('/img/pogo-bg.svg');
    background-repeat: no-repeat;
    background-position: right calc(50% + 526px) bottom -158px, right 50% top -252px, right 115px bottom 0;
    background-size: 793px, 693px, 350px;
    background-attachment: fixed;

    @include media-breakpoint-up(xl) {
        background-size: 793px, 693px, auto;
    }
}

.bg-line {
    display: flex;
    padding-top: 0;
    padding-top:95px;
}

.bg-solid {
    background-color: $c-0a0f26;
    padding-top:95px;
    position: relative;
    min-height: 100vh;
}

.bg-space {
    padding-top: 95px;
}

.scroll-overflow {
    height: calc(100vh - 95px);
    overflow: hidden;
    display: flex;
    align-items: stretch;
}

.scroll-content {
    height: calc(100vh - 95px);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
}

.scroll-sidebar {
    height: calc(100vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 15px;
    scrollbar-width: none;
}

.scroll-content-lp{
    height: calc(100vh - 135px);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;

    + footer {
        z-index: 1;
    }
}
