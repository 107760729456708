@import "../../styles/modules";

header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    @media only screen and (max-width: 768px) {
        border-bottom: 1px solid rgba($white, 0.1);

        .navbar {
            padding: 18px 0px 17px;
        }
    }
}

.navbar {
    display: flex;
    padding: 15px 0px;
    position: relative;
    justify-content: space-between;
    min-height: 80px;

    &Border {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    }

    &Left {
        display: flex;
        align-items: center;
    }

    &Center{
        flex-grow: 1;
        align-items: center;
        display: flex;
    }

    &Right {
        display: flex;
        align-items: center;
    }
}
