@import "../../../styles/modules";

.legend {
    width: 100%;
    padding: 15px;
    background-color: rgba($c-1e252e, 0.95);
    border-radius: 10px;
    margin-bottom: 30px;

    h3 {
        font-style: italic;
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 6px;
    }

    &Collapse {
        &Head {
            display: flex;
            align-items: center;
            min-height: 30px;

            > label {
                display: flex;
                align-items: center;
                font-size: 12px;
                text-transform: uppercase;
                line-height: 15px;
            }

            &:before {
                font-family: "POGO";
                content: "\e915";
                opacity: 0.5;
                margin-right: 7px;
                display: inline-flex;
                transform: rotate(-90deg);
                transition: transform 0.2s;
                cursor: pointer;
            }

            &Open {
                &:before {
                    opacity: 1;
                    transform: rotate(0);
                }
            }
        }

        &Caption {
            font-size: 12px;
            color: $c-a4a9bf;
            margin-left: 47px;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        &Body {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s 0s linear;

            &Open {
                max-height: 270px;
                transition-duration: 1.05s 0s linear;
            }

            label {
                display: flex;
                align-items: center;
                font-size: 12px;
                margin-top: 6px;
                margin-left: 47px;
            }

            > div:last-of-type {
                margin-bottom: 10px;
            }
        }
    }
}

input[type="radio"] {
    &.radio {
        & + label{
            padding-left: 24px;

            &:before {
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;

            }

            &:after {
                left: 5px;
                top: 5px;
            }
        }
        &:checked {
            & + label{
                &:before {
                    border-color: $white;
                    background-color: $white;
                }

                &:after {
                    background-color: $c-161a30;
                    width: 6px;
                    height: 6px;
                }
            }
        }
    }
}

