@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&family=Playfair+Display:ital,wght@0,400;0,500;1,400;1,500&family=Inter:wght@400;500;600&display=swap');

@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-Thin", 100, normal);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-Light", 300, normal);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-Regular", 400, normal);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-Italic", 400, italic);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-Medium", 500, normal);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-MediumItalic", 500, italic);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-Bold", 700, normal);
@include font-face("Clear Sans", "/static/fonts/clearsans/ClearSans-BoldItalic", Bold, italic);

@include font-face("POGO", "/static/fonts/pogo-icons/pogo-icons", normal, normal);