@import '../../styles/modules';

.wrapperPagination {
    padding: 17px 0;
    font-family: $font-clearsans;
    align-items: center;
    display: flex;
    justify-content: center;
    position:fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $c-0e1011;
    margin:0;
}

.paginationContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    label {
        color: $c-a4a9bf;
        margin-right: 8px;
    }
}

.paginationInfo {
    display: flex;
}

.nav {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;

    & > li {
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 32px;
        padding-right: 32px;
        position: relative;

        &:after {
            width: 1px;
            height: 25px;
            content: "";
            top: calc(50% - 12px);
            right: 0;
            position: absolute;
            background-color: rgba($color: $white, $alpha: .2);
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;

            &:after {
                display: none;
            }
        }
    }

    & ul {
        li {
            display: none;
        }
    }
}

.inputText {
    border: 1px solid rgba($color: $c-9396a5, $alpha: .5);
    color: $white;
    background-color: $tr;
    line-height: 24px;
    min-height: 36px;
    padding: 5px 12px;
    max-width: 75px;
    &:hover {
        border-color: $white;
    }
    &:focus {
        outline: none;
    }

    &::placeholder {
        opacity: 1;
        color: $white;
    }
}

.paginationRight {
    margin-left: 0;

    li {
        padding: 0;
        margin:0;

        &.previous,
        &.next {
            display: block;
            border: 1px solid rgba($color: $c-9396a5, $alpha: .5);

            a {
                color:$white;
                line-height: 34px;
                min-width: 34px;
                min-height: 34px;
            }

        }

        &.next {
            border-left: 0;
            margin-left: -1px;
        }

        &.disabled {
            a {
                pointer-events: none;
                color: rgba($color: $white, $alpha: .2)
            }
        }
    }
}
