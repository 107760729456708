@import "../../styles/modules";

.sidebarBlock {
    background-color: rgba($c-0e1011, 0.8);
    border-radius: 4px;
    padding: 12px 0px;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &Space {
        padding: 16px 20px;
    }

    &County {
        font-family: $font-clearsans;
        font-size: 14px;
        line-height: 26px;
        text-transform: uppercase;
        color: $c-a4a9bf;
        padding: 0 20px;
    }

    &Title {
        font-family: $font-clearsans;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: rgba($white, 0.6);
        margin-bottom: 12px;
        padding: 0 20px;
    }

    &Tabs {
        padding: 0;

        & > nav {
            border-bottom: 1px solid rgba($c-a4a9bf, 0.1);
            padding: 0 10px;

            & > a {
                font-family: $font-clearsans;
                font-size: 12px;
                line-height: 16px;
                padding: 19px 0px 15px;
                margin: 0 10px;
                text-transform: uppercase;
                color: $c-a4a9bf;
                text-decoration: none;
                display: inline-block;
                border-bottom: 2px solid $tr;
                margin-bottom: -1px;
                font-weight: 700;

                &:focus {
                    outline: none;
                }

                &.active {
                    color: $white;
                    border-color: $c-00d1ff;
                }
            }
        }

        ul {
            list-style: none;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 12px;
            display: flex;
            flex-wrap: wrap;

            li {
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                width: 100%;
                padding-right: 10px;
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 40px;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    width: 100%;
                    height: 0px;
                    border-bottom: 1px solid rgba($c-a4a9bf, 0.1);
                }

                &.viewAll {
                    align-items: flex-start;
                    justify-content: center;
                }

                &:nth-of-type(odd) {
                    padding-right: 19px;
                }
                &:nth-of-type(even) {
                    border-left: 1px solid rgba($c-a4a9bf, 0.1);
                    padding-left: 19px;
                }
                &:nth-last-child(-n + 2) {
                    margin-bottom: 20px;

                    &:after {
                        display: none;
                    }
                }
            }

            h3 {
                font-family: $font-playfair;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $white;
                margin: 0;
                letter-spacing: 0.5px;
            }

            h4 {
                font-family: $font-manrope;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.35px;
                color: rgba($c-a4a9bf, 0.9);
                margin: 0;
                margin-top: 10px;
            }

            span {
                font-size: 18px;
                margin-right: 10px;
                color: $c-a4a9bf;
                flex: 0 0 30px;

                width: 30px;
                height: 30px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.cyan {
                    color: $c-00d1ff;
                }
                &.orange {
                    color: $c-ff6e30;
                }
                &.purple {
                    color: $c-b02be3;
                }
                &.green {
                    color: $c-23c2b5;
                }
                &.lightGreen {
                    color: $c-23c2b5;
                }
            }
        }
    }

    &Info {
        padding: 15px 20px;
        font-family: Clear Sans;
        color: $c-a4a9bf;
        background-color: rgba($c-0e1011, 0.8);
        border-radius: 4px;
        padding: 15px 20px;
        margin-bottom: 10px;

        & > div {
            margin-left: -20px;
            margin-right: -20px;
        }

        span {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            display: block;
        }

        strong {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            margin-top: 2px;
            display: block;
        }

        .textWhite {
            color: $white;
        }

        .textBlue{
            color: #00d1ff
        }

        .col {
            position: relative;
            padding-left: 20px;
            padding-right: 20px;

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &:after {
                width: 1px;
                height: 100%;
                background-color: rgba($c-a4a9bf, 0.1);
                content: '';
                position: absolute;
                top:0;
                right: 0;
            }
        }
    }
}

.chartTitle{
    color: $white;
    font-size: 10px;
    font-family: $font-inter;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    margin-top: -12px;
    margin-bottom: 10px;
    padding-left: 45px;
    padding-right: 10px
}

.loading{
    position: absolute;
    right: 0;
    padding-right: 20px;
}

.sidebarBlockOverview {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
        padding: 0px 5px;
    }

    strong {
        font-family: $font-playfair;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 4px;
        display: block;
    }

    span {
        font-family: $font-clearsans;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: rgba($white, 0.6);
    }
}

.sidebarTabs {
    & > nav {
        border-bottom: 1px solid rgba($white, 0.2);
        display: block;
        margin-bottom: 15px;

        & > a {
            font-family: $font-clearsans;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
            color: rgba($white, 0.6);
            text-decoration: none;
            padding: 7px 0px 12px;
            margin: 0 10px;
            border-bottom: 2px solid transparent;
            display: inline-block;
            margin-bottom: -1px;

            &:focus {
                outline: none;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $white;
                border-bottom: 2px solid $white;
            }
        }
    }
}

.tableList {
    color: rgba($white, 0.6);
    font-size: 12px;
    line-height: 15px;
    border-collapse: collapse;
    margin: 0;
    padding: 0;

    thead {
        tr {
            border-bottom: 1px solid rgba($white, 0.2);

            th {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    th {
        text-align: left;
        font-weight: normal;
        padding: 0 10px 6px;
        vertical-align: middle;
    }

    td {
        padding: 11px 10px;
    }

    .value {
        font-family: $font-playfair;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: $white;
    }

    .type {
        color: $c-a4a9bf;
        text-transform: uppercase;
    }

    .title {
        color: $white;
    }

    .icon {
        font-size: 14px;
    }
}

.trasactionsItem {
    background-color: rgba($c-0e1011, 0.8);
    border-radius: 4px;
    padding: 12px 15px 8px;
    margin-bottom: 10px;
}

.sidebarPagination {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.horizontal {
    display: flex;
    align-items: center;
}
