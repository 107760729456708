.icon {
  font-family: "POGO";
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &Round {
    width: 32px;
    height: 32px;
    margin: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }

  &Filter:before {
    content: "\e910";
  }
  &ArrowUp:before {
    content: "\e907";
  }
  &ArrowDown:before {
    content: "\e90a";
  }
  &ArrowLeft:before {
    content: "\e90b";
  }
  &ArrowRight:before {
    content: "\e90c";
  }
  &Share:before {
    content: "\e90d";
  }
  &Search:before {
    content: "\e90e";
  }
  &Close:before {
    content: "\e905";
  }
  &Dark:before {
    content: "\e90f";
  }
  &Light:before {
    content: "\e906";
  }
  &Possible:before {
    content: "\e908";
  }
  &Faq:before {
    content: "\e909";
  }
  &Assistance:before {
    content: "\e900";
  }
  &Contracts:before {
    content: "\e901";
  }
  &Methodology:before {
    content: "\e902";
  }
  &AllIndustries:before {
    content: "\e903";
  }
  &Overview:before {
    content: "\e904";
  }
  &Anchor:before {
    content: "\e918";
  }
  &Cpu:before {
    content: "\e919";
  }
  &Heart:before {
    content: "\e91a";
  }
  &Truck:before {
    content: "\e91b";
  }
  &Zap:before {
    content: "\e91c";
  }
  &BankStatement:before {
    content: "\e91d";
  }
  &Cheque:before {
    content: "\e91e";
  }
  &PersonalTrainer:before {
    content: "\e91f";
  }
  &RateUp:before {
    content: "\e920";
  }
  &Stethoscope:before {
    content: "\e921";
  }
  &ChevronDown:before {
    content: "\e915";
  }
  &ChevronUp:before {
    content: "\e923";
  }
  &ChevronLeft:before {
    content: "\e920";
  }
  &ChevronRight:before {
    content: "\e921";
  }
  &Download:before {
    content: "\e922";
  }
  &CheckThick:before {
    content: "\e91f";
  }
  &Book:before {
    content: "\e913";
  }
  &Briefcase:before {
    content: "\e914";
  }
  &Database:before {
    content: "\e917";
  }
  &FileText:before {
    content: "\e91d";
  }
  &User:before {
    content: "\e91e";
  }
  &Information:before {
    content: "\e924";
  }
  &Info:before {
    content: "\e925";
  }
  &Help:before {
    content: "\e926";
  }
  &Facebook:before {
    content: "\e92b";
  }
  &Twitter:before {
    content: "\e92a";
  }
  &Url:before {
    content: "\e928";
  }
  &Mail:before {
    content: "\e929";
  }
  &Share:before {
    content: "\e90d";
  }
  &Delete:before {
    content: "\e92d";
  }
  &UpDown:before {
    content: "\e92e";
  }
  &Map:before {
    content: "\e92f";
  }
  &ZoomIn:before {
    content: "\e930";
  }
  &Layer:before {
    content: "\e931";
  }
  &CircleArrowDown:before {
    content: "\e932";
  }
  &ZoomOut:before {
    content: "\e933";
  }
  &Refresh:before {
    content: "\e934";
  }
  &ShareNew:before {
    content: "\e935";
  }
  &Flag:before {
    content: "\e936";
  }
  &ExternalLink:before {
    content: "\e937";
  }
  &YellowFlag:before {
    content: "\e939";
  }
  &RedFlag:before {
    content: "\e938";
  }
}
