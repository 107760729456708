.mapboxgl-popup-content{
  background: transparent !important;
  box-shadow: none !important;
  padding: 50px 10px 15px !important;
}
.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button{
  display: none;
}
