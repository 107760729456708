@import "../../../styles/modules";

.logo {
    border: 0 none;
    background: transparent none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;

    @media only screen and (max-width: 768px) {
        width: 100px;
    }

    &:focus {
        outline: none;
    }
}
