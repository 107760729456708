@import "../../../../styles/variables";

.circle {
    border-radius: 100%;
    background: $white;
    position: absolute;

    @for $i from 1 through 5 {
        &:nth-of-type(#{$i}) {
            width: calc(10px + 8px * #{$i - 1});
            height: calc(10px + 8px * #{$i - 1});
            opacity: 0.5;
            top: calc(50% - (10px + 8px * #{$i - 1}) / 2);
            left: calc(50% - (10px + 8px * #{$i - 1}) / 2);
        }
    }

    &:nth-of-type(1) {
        opacity: 1;
    }
    &:nth-of-type(4) {
        opacity: 0.4;
        position: relative;
    }
}
