@import "../../../styles/modules";

.searchRightContainer {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    flex: 0 0 100%;
}

.searchBlock {
    height: 49px;
    border-radius: 25px;
    display: flex;
    margin-right: 23px;

    & > div {
        width: 320px;

        & > div {
            &:first-child {
                border-radius: 25px;
                background-color: rgba($color: $black, $alpha: .6);
                height: 49px;
                padding-right: 20px;
            }
        }
    }

    input {
        padding-left: 20px;
        text-transform: uppercase;
    }
}




.navbar {
    &Border {
        border-bottom: 1px solid rgba($color: $white, $alpha: .2);
        padding: 15px 0px 14px;
        @extend .container;
    }
}
