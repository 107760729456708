@import "../../styles/modules";

.sidebarList {
	height: calc(100% - 30px);

	ul {
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
		height: calc(100% - 30px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-left: 2px solid $c-1b1f3a;
	}

	li {
		border-left: 2px solid transparent;
		color: rgba($color: $white, $alpha: 0.7);
		font-family: $font-manrope;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.35px;
		padding-left: 16px;
		cursor: pointer;
		transition: $transition-border;
		margin-left: -2px;
		padding-top: 24px;
		padding-bottom: 24px;

		@include media-breakpoint-down(xl) {
			padding-top: 12px;
			padding-bottom: 12px;
		}
		@media only screen and (max-height: 900px) {
			padding-top: 12px;
			padding-bottom: 12px;
		}
		@media only screen and (max-height: 700px) {
			padding-top: 6px;
			padding-bottom: 6px;
		}

		&:hover {
			border-color: $white;
			transition: $transition-border;
		}

		&.active {
			border-color: $c-00d1ff;
			color: $c-a4a9bf;

			strong {
				color: $c-00d1ff;
			}
		}

		//&:last-child {
		//    margin-bottom: 0;
		//    opacity: .4;
		//}

		strong {
			font-family: $font-playfair;
			font-weight: normal;
			font-size: 22px;
			line-height: 30px;
			color: $white;
			display: block;
		}
	}
}

@media only screen and (max-width: 768px) {
	.sidebarList {
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
			column-count: 2;
			column-gap: 20px;
			border-width: 0;
		}

		li {
			background: rgba($black, 0.9);
			border-radius: 10px;
			padding: 16px;
			margin: 10px 5px;
			border: none !important;
			width: calc(50% - 20px);

			.contentWrapper {
				padding-left: 16px;
				position: relative;
			}

			strong {
			}
		}
	}
}
