@import '../../../styles/modules';

.navbar {
    display: flex;
    padding: 24px 0px;
    position: relative;

    &Border {
        border-bottom: 1px solid rgba($color: $white, $alpha: .2);
    }
}
