@import "../../styles/modules";

.faqMenu {
  position: sticky;
  top: 0;

  @include media-breakpoint-down(md) {
    padding: 0 10px;
    position: relative;
  }

  h1 {
    font-size: 96px;
    line-height: 112px;
    font-family: $font-playfair;
    color: $white;
    font-weight: normal;
    margin-top: 0;
    letter-spacing: -1px;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 0;
    }
  }

  &Wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;

    @include media-breakpoint-down(md) {
      flex-direction: row;
      border-bottom: 1px solid rgba($c-a4a9bf, 0.1);
      width: 100%;
    }

    button {
      font-family: $font-playfair;
      display: block;
      appearance: none;
      background: none;
      border: none;
      color: $c-a4a9bf;
      outline: none;
      cursor: pointer;
      text-align: left;
      border-bottom: 1px solid rgba($c-a4a9bf, 0.1);
      font-size: 32px;
      transition: color 0.25s;
      padding: 12px 0 11px;

      &:not(:first-of-type) {
        font-size: 26px;
        padding-left: 20px;
      }

      @include media-breakpoint-down(md) {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        margin-right: 30px;
        letter-spacing: 0.5px;
        border-bottom: none;
        font-family: $font-clearsans;

        &:not(:first-of-type) {
          font-size: 18px;
          padding-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      &:first-of-type {
        border-top: 1px solid rgba($c-a4a9bf, 0.1);

        @include media-breakpoint-down(md) {
          border-top: none;
        }
      }

      &.faqMenuActive {
        color: $white;

        &:hover {
          color: $white;
        }

        @include media-breakpoint-down(md) {
          color: $c-00d1ff;
          border-bottom: 2px solid $c-00d1ff;

          &:hover {
            color: $c-00d1ff;
          }
        }
      }

      &:hover {
        color: rgba($white, 0.8);
      }
    }
  }

  &Question {
    font-family: $font-playfair;
    font-size: 22px;
    line-height: 30px;
    cursor: pointer;
    color: $c-a4a9bf;
    transition: color 0.35s ease-in-out;

    &Spacer {
      padding: 23px 20% 23px 43px;
    }
  }

  &Open {
    .faqItemToggler {
      &:before {
        content: "-";
      }
    }

    .faqItemQuestion {
      color: $white;
    }

    .faqItemAnswer {
      max-height: 350px;
      padding-bottom: 22px;
      opacity: 1;
    }

    .faqItemAnswerLong {
      max-height: 800px;
    }
  }

  &Toggler {
    font-size: 24px;
    font-family: $font-playfair;
    width: 78px;
    min-width: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background-color: $c-1b1f3a;
    cursor: pointer;

    &:before {
      content: "+";
    }
  }

  &Answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out, padding 0.35s ease-in-out, opacity 0.35s ease-in-out;
    padding: 0 20% 0 43px;
    opacity: 0;
    white-space: pre-line;
    font-family: $font-clearsans;
    color: $c-a4a9bf;
    font-size: 18px;
    line-height: 30px;

    a {
      color: $c-00d1ff;
      display: block;
    }
  }
}
