input[type="checkbox"] {
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            cursor: pointer;
            display: inline-flex;
            color: $c-a4a9bf;

            &:before {
                content: "";
                display: block;
                height: 15px;
                width: 15px;
                min-width: 15px;
                border: 1px solid rgba($white, 0.4);
                border-radius: 2px;
                background-color: transparent;
                transition: $transition-bg;
                margin-right: 10px;
            }

            &:after {
                @extend .icon;
                content: "\e91f";
                height: 15px;
                width: 15px;
                left: 0;
                top: 0;
                position: absolute;
                color: $c-0a0f26;
                border-radius: 2px;
                font-size: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &:not(:checked) {
        & + label {
            &:before {
                border-color: rgba($white, 0.4);
            }
            &:after {
                opacity: 0;
                transform: scale(0);
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                background-color: $white;
                border-color: transparent;
            }
            &:after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

@each $theme, $scheme in $check-color-schemes {
    @each $key, $color in $scheme {
        .checkbox-#{$theme} {
            &:nth-child(#{length($scheme)}n + #{(index(($scheme), ($key $color)))}) {
                input[type="checkbox"] {
                    & + label {
                        &:before {
                            background-color: $color !important;
                        }
                        &:after {
                            color: $white !important;
                        }
                    }

                    &:not(:checked) {
                        & + label {
                            &:before {
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
