.btn {
    background: transparent none;
    padding: 0;
    border: 0 none;
    cursor: pointer;

    &:focus {
        outline: 0 none;

    }

    &-link {
        font-family: $font-clearsans;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $c-00d1ff;

        &:hover {
            text-decoration: underline;
        }
    }

    &-disabled {
        background-color: grey!important;
        cursor: no-drop!important;
    }

    &-primary {
        font-family: $font-clearsans;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $c-07090d;
        background-color: $c-00d1ff;
        border-radius: 20px;
        height: 40px;
        white-space: nowrap;
        padding: 10px 32px;
        cursor: pointer;
        transition: $transition-bg;

        &:hover {
            background-color: darken($color: $c-00d1ff, $amount: 10);
            transition: $transition-bg;
        }
    }

    &-secondary {
        font-family: $font-clearsans;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $c-00d1ff;
        background-color: $c-1b333f;
        border-radius: 20px;
        height: 40px;
        white-space: nowrap;
        padding: 10px 32px;
        cursor: pointer;
        transition: $transition-bg;

        &:hover {
            background-color: darken($color: $c-1b333f, $amount: 10);
            transition: $transition-bg;
        }
    }


    &-arrow {
        display: inline-flex;
        align-items: center;

        svg {
            margin-top: 2px;
            margin-left: 3px;
        }

        path {
            fill: rgba($color: $white, $alpha: .6)
        }
    }


    &-outline-gray {
        font-family: $font-clearsans;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        background-color: $tr;
        border-radius: 20px;
        height: 40px;
        white-space: nowrap;
        padding: 10px 32px;
        cursor: pointer;
        transition: $transition-border, $transition-color;
        border: 1px solid rgba($color: $white, $alpha: .2);;

        &:hover {
            border-color: darken($color: $white, $amount: 10);
            transition: $transition-border, $transition-color;
        }
    }

    &-gray {
        font-size: 12px;
        line-height: 16px;
        color: rgba($color: $white, $alpha: .75);
    }

    &-sm {
        font-size: 12px;
        line-height: 16px;
    }

    &-left {
        text-align: left;
    }

    &-group {
        &-line {
            .btn {
                font-weight: bold;
                font-family: $font-clearsans;
                padding: 16px 10px;
                font-size: 14px;
                color: rgba($color: $white, $alpha: .75);
                position: relative;

                @include media-breakpoint-up(xxl) {
                    padding: 16px 20px;
                }


                &:after {
                    background-color: $tr;
                    height: 2px;
                    width:  100%;
                    content: '';
                    position:absolute;
                    left: 0;
                    bottom: -16px;
                    transition: $transition-bg;
                }

                &-active {
                    color: $white;

                    &:after {
                        background-color: $c-00d1ff;
                        transition: $transition-bg;
                    }
                    .icon {
                        color: $c-00d1ff;
                        transition: $transition-color;
                    }
                }

                .icon {
                    margin-right: 0;
                    transition: $transition-color;
                    @include media-breakpoint-up(xl) {
                        margin-right: 10px;
                    }
                }

                .text {
                    display: none;
                    @include media-breakpoint-up(xl) {
                        display: inline;
                    }
                }
            }
        }
    }
}
