@import "../../../styles/modules";

.subscribeBubble {
    width: 220px;
    padding: 20px 20px 28px;
    background-color: rgba($black, 0.8);
    border-radius: 10px;
    margin-bottom: 30px;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 8px;
    }

    p {
        color: rgba($white, 0.6);
        margin: 0 0 16px;
        font-size: 14px;
        line-height: 17px;
    }
}
