@import '../../styles/modules';

.mapLegendColor {
    @extend .row;
    @extend .no-gutters;
    width: calc(100% - 30px);
    min-height: 15px;
    line-height: 15px;
    font-family: $font-clearsans;
    font-weight: bold;
    font-size: 11px;
    display: flex;
    margin: 20px auto;

    .col {
        text-align: center;
        border-right: 2px solid $c-0a0f26;
        position: relative;

        &:last-child{
            border-right: 0 none;
        }

        strong {
            position:absolute;
            top: -18px;
            left: 0;
            transform: translateX(-50%);
        }
    }
}
