@import "../../styles/modules";

.industryOverviewsPage {
    display: flex;
    position: relative;
    font-family: $font-playfair;
    padding-top: 67px;
}

.info {
    position: relative;
    margin-bottom: 80px;

    &:before {
        position: absolute;
        content: "";
        width: calc(50% - 14px);
        height: 1px;
        background-color: $c-00d1ff;
        bottom: 0;
        left: 0;
    }

    &:after {
        position: absolute;
        content: "";
        width: calc(50% - 14px);
        height: 1px;
        background-color: $c-00d1ff;
        bottom: 0;
        right: 0;
    }
}

.count {
    color: $c-00d1ff;
    background: $tr url("/img/industry-count.svg") 50% no-repeat;
    width: 68px;
    height: 54px;
    margin: 0 auto 30px;
    font-family: $font-clearsans;
    line-height: 26px;
    text-align: center;
    padding-top: 25px;
    padding-right: 5px;
}

.title {
    font-style: normal;
    font-weight: normal;
    font-size: 56px;
    line-height: 66px;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.description {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 60px;

    &:after {
        border: solid $c-00d1ff;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 10px;
        content: "";
        position: absolute;
        bottom: -11px;
        left: calc(50% - 10px);
        transform: rotate(45deg);
    }
}

.listing {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 60px;
    @extend .row;
}

.item {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    &ImgContainer {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        background-position: 0 50%;
        background-size: cover;
        max-width: 78px;
        flex: 0 0 78px;
    }

    &Info {
        padding: 30px 0px 30px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    &Explore {
        font-size: 14px;
        color: $c-00d1ff;
        text-transform: uppercase;
        font-family: $font-clearsans;
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s;
    }

    a {
        text-decoration: none;
        margin-bottom: 10px;
        width: 100%;
        display: inline-flex;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;

        &:nth-child(even) {
            padding-left: 17px;
        }

        &:nth-child(odd) {
            padding-right: 17px;
        }
    }

    button {
        display: flex;
        text-align: left;
        flex: 0 0 100%;
        max-width: 100%;
        border: 1px solid $c-101416;
        background: $c-101416;
        border-radius: 10px;
        padding:0;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: $white;
        transition: $transition-border;
        cursor: pointer;
        align-items: stretch;
        justify-content: stretch;

        &:focus {
            outline: none;
        }

        &:hover {
            border: 1px solid $c-00d1ff;
            transition: $transition-border;

            .itemExplore {
                max-height: 30px;
            }
        }

        span {
            position: relative;
            padding-top: 8px;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 2px;
                width: 20px;
                border-radius: 1px;
                background: $c-00d1ff;
            }
        }

        img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
        }
    }
}
