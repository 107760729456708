@import "../../styles/modules";

footer {
    width: 100%;
    position: relative;
    z-index: 1;

    .footerContainer {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 18px;
        color: $c-a4a9bf;
        padding: 20px 0;
        border-top: 1px solid rgba($c-a4a9bf, 0.1);
    }

    .copyright {
        border-right: 1px solid rgba($c-a4a9bf, 0.1);
        padding-right: 17px;
        a {
            color: $c-a4a9bf;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .links {
        border-left: 1px solid rgba($c-a4a9bf, 0.1);
        padding-left: 17px;
        a {
            color: $c-a4a9bf;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &Contact {
            a {
                color: $c-a4a9bf;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .link {
        &Contact {
            color: $c-a4a9bf;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
