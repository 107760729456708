@import '../../../styles/modules';

.checkedButtons {
    display: flex;
    align-items: center;

    .checkedItem {
        border: 1px solid rgba($color: $white, $alpha: .2);
        background-color: rgba($color: $c-9396a5, $alpha: .1);
        border-radius: 5px;
        font-family: $font-clearsans;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        padding: 9px 12px 11px;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 10px;

        &:focus {
            outline: none;
        }

        span {
            margin-left: 10px;
            vertical-align: middle;
            color: rgba($color: $c-a4a9bf, $alpha: .6);
        }
    }
}
