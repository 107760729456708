@import "../../styles/icons";

.download {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;

    &Button {
        display: inline-block;
        align-items: center;
        margin: 0;
        text-align: right;

        span {
            margin-right: 4px;
            display: inline-block;
            text-decoration: none;
        }
    }
}
