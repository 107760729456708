@import "node_modules/bootstrap/scss/mixins";

@mixin scrollhide() {
    &::-webkit-scrollbar {
        height: 5px;
        width: 2px;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:horizontal {
        background: #a4a9bf;
        border-radius: 10px;
        cursor: pointer;
    }
}

.scroll-content-lp,
.scroll-sidebar,
.scroll-content,
.table-responsive {
    @include scrollhide();
}


.table {
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    font-family: $font-clearsans;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;

    &-scrollable {
        overflow: hidden;
        height: calc(100vh - 281px);

        @include media-breakpoint-up(xxl) {
            height: calc(100vh - 64px - 95px - 70px);
        }
    }

    &-responsive {
        display: block;
        width: 100%;
        overflow-y: scroll;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - 323px);

        @include media-breakpoint-up(xxl) {
            height: calc(100vh - 64px - 95px - 70px - 42px);
        }
    }

    tr {
        th,
        td {
            text-align: left;
            vertical-align: top;
        }

        th {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba($color: $white, $alpha: 0.6);
            padding: 0px 20px;
            vertical-align: middle;
        }

        td {
            color: $c-a4a9bf;
            border-bottom: 1px solid rgba($color: $white, $alpha: 0.1);
            line-height: 20px;
            position: relative;
            padding: 20px 20px 40px;
        }
    }

    thead {
        th {
            padding: 10px 20px;
            background-color: $c-121315;
            white-space: nowrap;

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            .icon {
                margin-left: 2px;
                margin-top: 2px;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr {
            cursor: pointer;

            &.active {
                td {
                    color: rgba($color: $white, $alpha: 1);
                }

                .iconChevronDown {
                    transform: rotate(180deg);
                    transition: trasform 0.35 ease-in-out;
                }
            }
        }
    }

    .nowrap {
        white-space: nowrap;
    }
}

.sticky-th {
    min-width: 120px;
    right: 0;
    position: sticky !important;
    z-index: 1;
    background: linear-gradient(
        to right,
        rgba($color: $c-121315, $alpha: 0) 0%,
        rgba($color: $c-121315, $alpha: 1) 15%,
        rgba($c-121315, $alpha: 0) 100%
    );

    .order {
        a {
            white-space: pre-line;
        }
    }

    .order-list {
        left: -134px;
        top: 40px;
        width: 260px;
        &:before {
            right: 14px;
            left: initial;
        }

        ul li a {
            white-space: initial;
        }
    }
}

.sticky-td {
    right: 0;
    position: sticky !important;
    border-bottom-right-radius: 0 !important;
    background: linear-gradient(to right, rgba(25, 26, 27, 0) 0%, rgba(25, 26, 27, 1) 15%, rgba(25, 26, 27, 1) 100%);
}

.action-cell {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    button {
        background: 0 none;
        padding: 0;
        margin-top: 4px;
        border: 0 none;
        color: rgba($color: $c-ff314a, $alpha: 1);
        position: relative;
        margin-left: 2px;
        cursor: pointer;
        transition: $transition-color;

        .yellow {
            color: $c-ffca0f;
        }

        &:focus {
            outline: 0 none;
        }

        &:hover {
            color: rgba($color: $c-ff314a, $alpha: 1);
        }

        /* &:before {
            background-color: rgba($color: $white, $alpha: .5);
            width: 1px;
            height: 12px;
            content: '';
            position: absolute;
            top: calc(50% - 6px);
            left: -10px;
        } */

        &.active {
            color: $white;
            transition: $transition-color;
        }
    }
}

.order {
    position: relative;
    display: inline-block;

    & > a {
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &-list {
        position: absolute;
        background-color: $c-1e252e;
        font-size: rem(16px);
        line-height: rem(22px);
        color: $white;
        width: 160px;
        z-index: 1;
        top: 26px;
        left: calc(100% - 26px);
        text-transform: none;
        text-align: center;

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 0;
        }

        li {
            border-left: 2px solid $tr;
            display: flex;
            padding-top: 10px;
            padding-bottom: 10px;
            transition: color 0.35s ease-in-out, border 0.35s ease-in-out;
            cursor: pointer;

            a {
                margin-left: 10px;
                text-decoration: none;
                transition: color 0.35s ease-in-out;
            }

            &:hover {
                border-left: 2px solid $tr;
                transition: border 0.35s ease-in-out;

                a {
                    text-decoration: none;
                    color: rgba(#00d1ff, 0.4);
                    transition: color 0.35s ease-in-out;
                }
            }

            &.is-active {
                color: rgba(#00d1ff, 0.4);
                border-left: 2px solid rgba(#00d1ff, 0.4);
                transition: color 0.35s ease-in-out, border 0.35s ease-in-out;

                &:hover {
                    transition: color 0.35s ease-in-out, border 0.35s ease-in-out;

                    a {
                        color: rgba(#00d1ff, 0.4);
                        text-decoration: none;
                        transition: color 0.35s ease-in-out;
                    }
                }

                a {
                    color: rgba(0, 209, 255, 0.4);
                    text-decoration: none;
                }
            }
        }

        &::before {
            width: 14px;
            height: 14px;
            transform: rotate(45deg);
            background-color: $c-1e252e;
            position: absolute;
            top: -6px;
            left: 13px;
            z-index: 1;
            content: "";
        }
    }
}

.filterTopColumn + .table-scrollable {
    @include media-breakpoint-up(xxl) {
        height: calc(100vh - 269px);
    }

    @include media-breakpoint-up(3xl) {
        height: calc(100vh - 229px);
    }

    .table-responsive {
        @include media-breakpoint-up(xxl) {
            height: calc(100vh - 311px);
        }

        @include media-breakpoint-up(3xl) {
            height: calc(100vh - 271px);
        }
    }
}
