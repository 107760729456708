.img-fluid {
    @include img-fluid();
}

.mt-0 {
    margin-top: 0 !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ml-0 {
    margin-left: 0 !important;
}

@for $i from 1 to 6 {
    .mt-#{$i} {
        margin-top: #{$i * 10}px;
    }
    .mb-#{$i} {
        margin-bottom: #{$i * 10}px;
    }
    .ml-#{$i} {
        margin-left: #{$i * 10}px;
    }
    .mr-#{$i} {
        margin-right: #{$i * 10}px;
    }
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-200 {
    margin-top: 200px;
}

.mt-4 {
    @media only screen and (max-width: 768px) {
        margin-top: 20px;
    }
}

@for $i from 1 to 6 {
    .pt-#{$i} {
        padding-top: #{$i * 10}px;
    }
    .pb-#{$i} {
        padding-bottom: #{$i * 10}px;
    }
    .pl-#{$i} {
        padding-left: #{$i * 10}px;
    }
    .pr-#{$i} {
        padding-right: #{$i * 10}px;
    }
}

@each $name, $color in $landing-list-colors {
    $i: index($landing-list-colors, $name $color);
    &.color-#{$i} {
        background-color: $color;
    }
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}


.flex-grow-1 {
    flex-grow: 1;
}

.fade {
    transition: opacity 0.15s linear;

    &:not(.show) {
        opacity: 0;
    }
}

.badge {
    background-color: $c-ff6e30;
    color: $white;
    font-family: $font-clearsans;
    min-width: 14px;
    min-height: 14px;
    line-height: 12px;
    font-size: 12px;
    text-align: center;
    border-radius: 7px;
}

.tab-content {
    & > .tab-pane {
        display: none;
    }

    & > .active {
        display: block;
    }
}


.loading {
    padding-top: 50px;
    text-align: center;

    &-note {
        display: inline-block;
        text-align: center;
        font-family: $font-clearsans;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $c-00d1ff;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-top: 50px;
        border-bottom: 3px solid $c-00d1ff;
    }

    &-title {
        text-align: center;
        font-family: $font-playfair;
        font-weight: normal;
        font-size: 44px;
        line-height: 52px;
        letter-spacing: -0.5px;
        margin: 0 0 10px;
    }

    &-subtitle {
        text-align: center;
        font-family: $font-playfair;
        font-style: italic;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        margin: 0;
    }
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-grow {
    flex-grow: 1;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-stretch {
    align-items: stretch;
}

.justify-start{
    justify-content: flex-start;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.flex{
    display: flex;
}

.text-center {
    text-align: center;
}

.search-result {
    color: $white;
    font-size: 14px;
    font-family: $font-clearsans;
    line-height: 32px;

    .total {
        color: $c-a4a9bf;
    }
}

.vertical-chart-title,
.horizontal-chart-title {
    font-style: italic;
    opacity: 0.8;
}

.overflow-hidden {
    overflow: hidden;
}

.indented {
    padding-left: 30px !important;
}

.table-caption {
    margin-bottom: 10px;
    justify-content: space-between;

    &-left {
        display: flex;
        flex-grow: 1;
    }

    &-right {
        display: flex;

        & > div {
            position: relative;
            display: flex;
            align-items: center;
            padding: 0px 29px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                width: 1px;
                height: 20px;
                content: '';
                position: absolute;
                top: calc( 50% - 10px);
                right: 0;
                background-color: rgba($color: $white, $alpha: .2);
            }
        }
    }

}

.react-transform-component,
.react-transform-element {
    width: 100% !important;
}


.apexcharts-tooltip {
    border: 0 none !important;
    background: $c-1e252e none !important;
    position: relative;
    border-radius: 10px;
    box-shadow: none !important;
    padding:10px;
    white-space: initial !important;
    min-width: 180px;

    .tooltip-zip {
        padding-bottom: 14px;
        span {
            font-family: $font-clearsans;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: rgba(255,255,255,.75);
            display: block;
        }

        strong {
            font-family: $font-playfair;
            font-weight: normal;
            font-size: 22px;
            line-height: 30px;
        }
    }

    .tooltip-line {
        border-top: 1px solid rgba(255,255,255,.2);
        padding: 4px 0px;
        display: flex;
        align-items: center;
        font-family: $font-clearsans;

        &:last-child {
            padding-bottom: 0;
        }

        span {
            font-weight: normal;
            font-size: 10px;
            line-height: 1.2;
            text-transform: uppercase;
            color: rgba(255,255,255,.75);
            flex: 0 0 65%;
            max-width: 65%;
        }

        strong {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            flex: 0 0 35%;
            max-width: 35%;
        }
    }
}

.apexcharts-marker:hover {
    stroke: white !important;
    stroke-width: 2px !important;
}

.order-list li a {
    white-space: break-spaces;
    text-align: left;
}

.left-info {
    font-family: $font-clearsans;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba($color: $white, $alpha: .6);
    margin: 0 0 10px;
}

.header {
    z-index: 2;
    position: fixed;
    transition: background .35s ease-in-out;
}

.sticky {
    background-color: rgba($color: $black, $alpha: .8);
    transition: background .35s ease-in-out;
    nav {
        border-bottom-color: $tr;
    }
}

@each $name, $color in $landing-legend-colors {
    $i: index($landing-legend-colors, $name $color);
    &.col-#{$i - 1} {
        background-color: $color;
    }
}
