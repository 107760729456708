@import "../../../styles/modules";

.mapSearch {
	width: 300px;
	position: relative;

	&Loading {
		background-color: $c-0e1620;
		font-size: 14px;
		color: $white;
		padding: 8px 16px;
	}

	&Input {
		min-height: 38px;
		transition: all 100ms ease 0s;
		box-sizing: border-box;
		border: none;
		font-family: $font-clearsans;
		font-size: 14px;
		outline: 0px !important;
		padding: 0px 10px 0px 16px;
		background-color: transparent;
		display: flex;
		flex-grow: 1;
		color: $c-a4a9bf;
		text-transform: uppercase;

		&::placeholder {
			color: $c-a4a9bf;
		}
	}

	&Wrap {
		align-items: center;
		border-radius: 20px;
		overflow: hidden;
		justify-content: space-between;
		position: relative;
		flex-grow: 0;
		background-color: $black;
		border: 1px solid $black;
		display: inline-flex;
		padding-right: 12px;
		width: 100%;
	}

	&Results {
		border-radius: 5px;
		background-color: $c-0e1620;
		overflow-x: auto;
		margin-top: 8px;
		position: absolute;
		z-index: 5;
		width: 100%;
		max-height: 305px;
		&::-webkit-scrollbar {
			height: 2px;
			width: 5px;
			background: transparent;
		}

		&::-webkit-scrollbar-thumb:vertical {
			background: #a4a9bf;
			border-radius: 10px;
			cursor: pointer;
		}
	}

	&Suggestion {
		font-size: 14px;
		color: $white;
		padding: 8px 16px;
		cursor: pointer;

		&:first-of-type {
			margin-top: 8px;
		}
		&:last-of-type {
			margin-bottom: 8px;
		}

		&Active {
			background-color: rgba($c-00d1ff, 0.2);
		}
	}
}
