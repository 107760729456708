@import "../../styles/modules";

.slider {
    width: 100%;
    position: relative;
    height: 286px;
    margin-bottom: 30px;

    & > div:first-child {
        height: 148px;

        &:hover {
            height: auto;
            z-index: 2;
            margin-bottom: 0px;
        }
    }

}



.slide {
    width: 160px;
    background-color: $tr;
    position: relative;
    border-radius: 10px;
    text-align: center;
    padding-bottom: 0;
    margin-top: 48px;
    height:100px;
    overflow: hidden;
    transition: $transition-bg;

    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    &ImgContainer {
        position: relative;
        height: 100px;
        border-radius: 5px;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.3);
            transition: opacity 0.2s;
            top: 0;
            left: 0;
            opacity: 1;
        }
    }

    h3 {
        font-family: $font-playfair;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        position: absolute;
        margin: 0;
        top: 65px;
        left: 0;
        right: 0;
        transition: top 0.35s ease-in-out;
    }

    img {
        border-radius: 5px;
        display: block;
        max-width: 100%;
    }

    ul {
        list-style: none;
        margin: 6px 0 10px;
        padding-left: 0;
        opacity: 0;
        transition: opacity 0.35s ease-in-out;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font-clearsans;
            padding: 4px 15px;
            border-bottom: 1px solid rgba($color: $c-c4c4c4, $alpha: 0.1);
        }
    }

    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: $c-a4a9bf;
        text-transform: uppercase;
        text-align: left;
    }

    strong {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
    }

    button {
        opacity: 0;
        transition: opacity 0.35s ease-in-out;
    }

    &:hover {
        background-color: $c-101416;
        transition: $transition-bg;
        padding-bottom: 8px;
        padding-top: 48px;
        margin-top: 0;
        height:auto;
        overflow: initial;
        z-index: 10;

        h3 {
            top: 8px;
        }
        ul {
            opacity: 1;
            transition: opacity 0.35s ease-in-out;
        }

        button {
            opacity: 1;
            transition: opacity 0.35s ease-in-out;
        }

        .slideImgContainer {
            &:after {
                opacity: 0;
            }
        }
    }
}

.nav {
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;

    &Container {
        @extend .container;
        z-index: 1;
        position: absolute;
        width: 100%;
        left: 50%;
        top: 200px;
        transform: translate3d(-50%, 0, 0);
    }

    &Left,
    &Right {
        width: 36px;
        height: 36px;
        font-size: 24px;
        margin: 5px;
        color: $c-a4a9bf;
        border: 1px solid $c-a4a9bf;
        border-radius: 18px;
        background-color: $tr;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &.disable {
            opacity: 0.5;
        }
    }

    &Right {
        margin-right: 0;
    }
}

.scrollbar {
    -ms-touch-action: none;
    background: rgba($color: $c-a4a9bf, $alpha: 0.2);
    position: relative;
    z-index: 50;
    height: 1px;
    width: 100%;
}
.scrollbarDrag {
    height: 3px;
    width: 100%;
    position: relative;
    background: $c-00d1ff;
    border-radius: 2px;
    left: 0;
    top: -1px;
}
.swiper-scrollbar-cursor-drag {
    cursor: move;
}
.swiper-scrollbar-lock {
    display: none;
}

.lazy {
    opacity: 0;

    &Loading {
        opacity: 0;
    }

    &Loaded {
        opacity: 1;
        transition: opacity 0.35s ease-in-out;
    }

    &Preloader {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -10px;
        margin-top: -10px;
        z-index: 10;
        transform-origin: 50%;
        animation: swiper-preloader-spin 1s infinite linear;
        box-sizing: border-box;
        border: 2px solid $c-00d1ff;
        border-radius: 50%;
        border-top-color: transparent;
    }

    @keyframes swiper-preloader-spin {
        100% {
            transform: rotate(360deg);
        }
    }
}
