@import '../../../styles/modules';

.moreFilters {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &Button{
        background: $tr none;
        padding: 8px 0 8px 0px;
        border: 0 none;
        color: $white;
        font-size: 14px;
        white-space: nowrap;
        cursor: pointer;
        font-family: $font-clearsans;

        &:focus {
            outline: none;
        }

        span {
            margin-right: 16px;
            vertical-align: middle;
        }
    }

    &Dropdown{
        background-color: rgba($color: $c-1e252e, $alpha: 1);
        border-radius: 10px;
        padding: 20px 15px 20px;
        position: absolute;
        width: 272px;
        top: 0;
        left: calc(50% - 136px);
        margin-top: 46px;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid rgba($color: $c-1e252e, $alpha: 1);
            left: calc(50% - 8px);
            top: -8px;
        }

        &Item {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .col-6 {
                background: transparent;
            }
        }

        label {
            font-family: $font-clearsans;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: rgba($color: $white, $alpha: .8);
            margin-bottom: 8px;
            display: inline-block;
        }
    }
}

.input {
    background-color: $c-0e1620;
    border-radius: 4px;
    border: 0 none;
    width: 100%;
    font-size: 14px;
    font-family: $font-clearsans;
    padding: 12px 16px;
    color: $white;

    &::placeholder {
        opacity: 1;
        color: $white;
    }

    &:focus {
        outline: none;
    }


    &Number {
        width: 100%;
        background-color: $c-0e1620;
        border-radius: 4px;
        border: 0 none;
        width: 100%;
        font-size: 14px;
        font-family: $font-clearsans;
        padding: 12px 16px;
        color: $white;
        display: block;

        &::placeholder {
            opacity: 1;
            color: $white;
        }

        &:focus {
            outline: none;
        }
    }
}
