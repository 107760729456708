@import '../../styles/modules';

.displayMode {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 20px;

    @include media-breakpoint-up(xxl) {
        padding-left: 50px;
    }
}
