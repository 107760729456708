@import '../../styles/modules';

.legend{
    position: absolute;
    bottom: 0;
    right: 1%;
}

.map {

    &Container {
        height: 100%;
        background-color: rgba($color: $black, $alpha: .6);
        position: relative;
        z-index: 1;
    }

    &Search {
        position:absolute;
        width: 300px;
        top: 20px;
        left: 20px;
        display: flex;
    }

    &Layer {
        position:absolute;
        width: 260px;
        top: 20px;
        right: 20px;
        border: 1px solid $black;
        background-color: $black;
        border-radius: 20px;
    }

    &Control {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 1;
    }

    &Refresh {
        position: relative;
        background-color: $c-1d252e;
        border-radius: 50%;
        border: 1px solid $c-191a1b;
        color: white;
        font-size: 20px;
        width: 38px;
        height: 38px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        cursor: pointer;

        span {
            margin-top: 2px;
        }

        &:focus {
            outline: 0 none;
        }
    }

    &Zoom {
        position: relative;
        background-color: $c-1d252e;
        border-radius: 19px;
        border: 1px solid $c-191a1b;

        &:before {
            width: 18px;
            height: 1px;
            background-color: rgba($color: $c-c4c4c4, $alpha: .1);
            position:absolute;
            top: 50%;
            left: calc(50% - 9px);
            content: "";
        }

        &In,
        &Out {
            width: 36px;
            height: 36px;
            display: block;
            border: 0 none;
            position: relative;
            cursor: pointer;
            background-color:$tr;
            border-radius: 18px;

            &:focus {
                outline: none;
            }

            &:before {
                content: '';
                width: 12px;
                height: 2px;
                border-radius: 2px;
                background-color: $white;
                position: absolute;
                top: calc( 50% - 1px);
                left: calc( 50% - 6px);
                transition: background-color .35s ease-in-out;
            }

            &:hover {
                &:before,
                &:after{
                    background-color: $c-00d1ff;
                    transition: background-color .35s ease-in-out;
                }
            }
        }

        &In {
            margin-bottom: 5px;

            &:after {
                content: '';
                height: 12px;
                width: 2px;
                border-radius: 2px;
                background-color: $white;
                position: absolute;
                left: calc( 50% - 1px);
                top: calc( 50% - 6px);
                transition: background-color .35s ease-in-out;
            }
        }
    }

    &Earth {
        background: rgba($color: $black, $alpha: 0.6) url("/img/earth.svg") 50% no-repeat;
        min-height: 720px;
        color: $white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-family: $font-playfair;
            font-weight: normal;
            font-size: 32px;
            line-height: 40px;
            margin: 0 0 15px;
        }

        p {
            font-family: $font-clearsans;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            margin: 0 0 15px;

            &:last-child {
                margin: 0;
            }
        }
    }
}



