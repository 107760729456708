@import "../../styles/modules";

.headingTitle {
    @media only screen and (max-width: 768px) {
        font-family: $font-playfair;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        margin: 20px 0 15px;
        position: relative;
        z-index: 1;
        letter-spacing: 0.4px;
    }
}

.headingSubtitle {
    font-family: $font-playfair;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    font-weight: normal;
    font-style: italic;
    margin: 12px 12vw;
    position: relative;
    z-index: 1;

    span {
        color: $c-00d1ff;
        font-style: initial;
        font-weight: normal;
        line-height: 1;
        display: inline-block;
    }
}

.staticMap {
    position: absolute;
    top: calc(100% - 60px);
    z-index: 0;
}

.backgroundEffect1 {
    position: absolute;
    width: 378.3px;
    height: 378.3px;
    left: 96.7px;
    top: -43.13px;
    background: linear-gradient(180deg, $c-2ba8c3 0%, $c-2b59c3 100%);
    opacity: 0.2;
    filter: blur(250px);
}

.backgroundEffect2 {
    position: absolute;
    width: 442.1px;
    height: 442.1px;
    left: -110px;
    top: 279.03px;
    background: linear-gradient(180deg, #2b59c3 0%, rgba(150, 31, 206, 0.65) 100%);
    opacity: 0.2;
    filter: blur(250px);
}

.mapSpacer {
    margin-bottom: 220px;
}

.mapBg {
    background-image: url("/img/map-bg.png");
}
