@import "../../styles/modules";

.h1{
    font-family: $font-playfair;
    font-weight: normal;
    font-size: 56px;
    line-height: 66px;
    letter-spacing: -0.5px;
    margin:0;
    padding-bottom: 50px;
    text-align: center;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notFoundPage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
