@import "../../../styles/modules";

.goBack {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 0;
    color: $c-00d1ff;
    display: flex;
    background-color: $tr;
    border: 0 none;
    align-items: center;
    font-family: $font-clearsans;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        span {
            background-color: rgba($color: $c-00d1ff, $alpha: 0.4);
            transition: $transition-bg;
        }
    }

    &:focus {
        outline: none;
    }

    span {
        border-radius: 50%;
        width: 24px;
        font-size: 18px;
        height: 24px;
        margin-right: 8px;
        line-height: 24px;
        text-align: center;
        background-color: rgba($color: $c-00d1ff, $alpha: 0.1);
        transition: $transition-bg;
    }
}

.industryDetailedPage {
    position: relative;
    font-family: $font-playfair;
    padding-top: 52px;
}

.info {
    position: relative;
    margin-bottom: 80px;

    &:before {
        position: absolute;
        content: "";
        width: calc(50% - 14px);
        height: 1px;
        background-color: $c-00d1ff;
        bottom: 0;
        left: 0;
    }

    &:after {
        position: absolute;
        content: "";
        width: calc(50% - 14px);
        height: 1px;
        background-color: $c-00d1ff;
        bottom: 0;
        right: 0;
    }
}

.titleIndustry {
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 46px;
    margin-top: 0;
    padding-bottom: 50px;
    position: relative;

    @include media-breakpoint-up(lg) {
        font-size: 36px;
        line-height: 46px;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 46px;
        line-height: 56px;
    }

    @include media-breakpoint-up(3xl) {
        font-size: 56px;
        line-height: 66px;
    }

    &:after {
        border: solid $c-00d1ff;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 10px;
        content: "";
        position: absolute;
        bottom: -11px;
        left: calc(50% - 10px);
        transform: rotate(45deg);
    }
}

.description {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 60px;

    &:after {
        border: solid $c-00d1ff;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 10px;
        content: "";
        position: absolute;
        bottom: -11px;
        left: calc(50% - 10px);
        transform: rotate(45deg);
    }
}

.noRecords {
    color: #a4a9bf;
    line-height: 1.6;
    margin-bottom: 20px;
}

.listCells {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
        border-right: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
        border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
        max-width: 20%;
        flex: 0 0 20%;

        &:nth-child(5n) {
            border-right: 1px solid $tr;
        }

        &:nth-last-child(-n + 5) {
            border-bottom: 1px solid $tr;
        }

        &:hover {
            div {
                background-color: $c-161a30;
                border-color: $c-00d1ff;
                transition: $transition-bg, $transition-border;
            }

            strong {
                border-color: $c-00d1ff;
                transition: $transition-border;
            }
        }
    }

    div {
        padding: 20px 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid $tr;
        transition: $transition-bg, $transition-border;
    }

    strong {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        display: block;
        color: $white;
        padding-left: 5px;
        border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
        padding-bottom: 7px;
        margin-bottom: 7px;
        transition: $transition-border;
    }

    span {
        font-family: $font-manrope;
        color: $c-a4a9bf;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.35px;
    }
}

.listCellsTop {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
        border-right: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
        border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
        max-width: 25%;
        flex: 0 0 25%;

        &:nth-child(4n) {
            border-right: 1px solid $tr;
        }

        &:nth-last-child(-n + 4) {
            border-bottom: 1px solid $tr;
        }

        &:hover {
            div {
                background-color: $c-161a30;
                border-color: $c-00d1ff;
                transition: $transition-bg, $transition-border;
            }

            strong {
                border-color: $c-00d1ff;
                transition: $transition-border;
            }
        }
    }

    div {
        padding: 20px 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid $tr;
        transition: $transition-bg, $transition-border;
    }

    strong {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        display: block;
        color: $white;
        margin-bottom: 4px;
    }

    span {
        font-family: $font-manrope;
        color: $c-a4a9bf;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.35px;
    }
}

.detailedLists {
    border-top: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);
    margin-bottom: 60px;

    .title {
        font-weight: normal;
        font-size: 44px;
        line-height: 52px;
        letter-spacing: -0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .subtitle {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        color: $c-a4a9bf;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.description {
    &Left {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 38px;

        p {
            margin-top: 0;
        }
    }

    &Right {
        font-family: $font-clearsans;
        font-size: 14px;
        line-height: 20px;
        color: rgba($color: $white, $alpha: 0.6);
        margin-bottom: 50px;

        p {
            margin: 0 0 10px;
        }
    }

    &Full {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 38px;
    }
}

.total {
    &Info {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin: 0 0 47px;

        li {
            max-width: calc(100% / 3);
            flex: 0 0 calc(100% / 3);
            border-left: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);

            &:first-child {
                border-left: 0 none;
            }

            strong {
                font-weight: normal;
                font-size: 26px;
                line-height: 42px;
                letter-spacing: -1px;
                display: block;

                @include media-breakpoint-up(lg) {
                    font-size: 46px;
                    line-height: 62px;
                }

                @include media-breakpoint-up(xxl) {
                    font-size: 76px;
                    line-height: 92px;
                }

                @include media-breakpoint-up(3xl) {
                    font-size: 96px;
                    line-height: 112px;
                }
            }

            span {
                font-family: $font-clearsans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: $c-00d1ff;
            }
        }
    }

    &Spending {
        font-size: 24px;
        line-height: 32px;
        text-align: right;
        letter-spacing: -0.5px;
        color: $c-00d1ff;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
            line-height: 42px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 34px;
            line-height: 42px;
        }

        @include media-breakpoint-up(3xl) {
            font-size: 44px;
            line-height: 52px;
        }
    }

    &Spent {
        display: flex;
        justify-content: center;
    }

    &Awards {
        display: flex;
        justify-content: flex-end;
    }
}
