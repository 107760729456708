.pagination {
    list-style: none;
    margin: 0 -10px;
    padding-left: 0;
    font-size: 16px;
    line-height: 24px;
    color: $c-9396a5;
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    &.previous,
    &.next {
        display: none;
    }

    li {
        display: block;
        text-align: center;
        margin: 0 10px;
        min-width: 30px;

        &:focus {
            outline: none;
        }

        &.active {
            background-color: $primary;
            border-radius: 15px;

            a {
                color: $white;
            }
        }

        &.previous,
        &.next {
            display: none;
        }

        &.break-me {
            margin: 0;
        }

        a {
            line-height: 30px;
            padding: 0 4px;
            text-decoration: none;
            color: $white;
            display: block;
            min-width: 22px;
            min-height: 30px;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }

}