@import "../../../styles/modules";

$navbar-width: 360px;

.sidebar {
    &Heading {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 28px;
        padding-left: 24px;
        position: relative;
        font-family: $font-clearsans;

        .icon {
            font-size: 16px;
            margin-right: 12px;

            &Round {
                background: $c-2b59c3;
            }
        }

        &Title {
            display: flex;
            align-items: center;
            font-weight: 400;
        }
    }

    &Label {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.35px;
        color: $white;
        opacity: 0.7;
        display: block;
        margin-bottom: 8px;
        margin-top: 0;
    }

    &Close {
        width: 16px;
        height: 16px;
        background: transparent;
        border: none;
        position: relative;
        transition: opacity 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        &:before {
            position: absolute;
            content: " ";
            height: 16px;
            width: 1.5px;
            background-color: $c-a4a9bf;
            transform: rotate(45deg);
        }

        &:after {
            position: absolute;
            content: " ";
            height: 16px;
            width: 1.5px;
            background-color: $c-a4a9bf;
            transform: rotate(-45deg);
        }
    }

    &Body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        font-family: $font-clearsans;
        padding: 0px 25px;
    }

    &Main {
        padding: 0;
        margin-top: 26px;
    }

    &Description {
        margin-bottom: 20px;
        border-top: 1px solid $c-363B55;

        h3 {
            font-size: 20px;
            line-height: 26px;
            font-family: $font-playfair;
            font-weight: normal;
            margin: 10px 0;
        }

        &Text {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 9px;
            a {
                color: $c-00d1ff;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &Footnotes {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.35px;
            color: $c-a4a9bf;
            border-top: 1px solid $c-363B55;
            padding-top: 9px;
            margin-bottom: 9px;

            p {
                color: $white;
                margin: 0;
            }
        }
    }

    &Secondary {
        position: relative;
    }

    &Actions {
        display: flex;
        margin: 24px 0;

        button {
            flex-grow: 1;
            width: 50%;

            &:first-of-type {
                margin-right: 8px;
            }
            &:last-of-type {
                margin-left: 8px;
            }
        }
    }
}

.options {
    display: flex;
    flex-wrap: wrap;

    &Selected {
        display: flex;
        font-size: 12px;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(147, 150, 165, 0.1);
        color: $white;
        border-radius: 5px;
        margin-top: 8px;
        margin-right: 8px;
        align-items: center;
        outline: none;
        cursor: pointer;
    }

    &DeselectBtn {
        color: $white;
        opacity: 0.3;
        margin-left: 10px;
    }
}

.stats {
    display: flex;
    font-size: 12px;
    letter-spacing: 0.35px;
    color: $c-a4a9bf;
    line-height: 1.6;
    border-top: 1px solid $c-363B55;
    border-bottom: 1px solid $c-363B55;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 9px;

    span {
        color: $white;
    }

    &Group {
        display: flex;
        min-width: 130px;
    }

    &SpacedBox {
        margin-left: 24px;
    }
}


.data {

    &Item {
        display: flex;
        padding: 5px 0 7px;
        margin-left: -10px;
        margin-right: -10px;

        &:nth-child(odd) {
            background-color: rgba($color: $c-a4a9bf, $alpha: .06);
        }

        &Half {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 10px;
            padding-right: 10px;
        }

        &Full {
            padding-left: 10px;
            padding-right: 10px;
            flex: 0 0 100%;
            max-width: 100%;
        }

        strong {
            font-family: $font-clearsans;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.35px;
            color: rgba($color: $white, $alpha: .6);
            display: block;
        }

        span {
            font-family: $font-inter;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
        }
    }

}



