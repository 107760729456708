@import "../../../styles/modules";

.spending {
    width: 220px;
    padding: 24px;
    background: rgba($black, 0.8);
    border-radius: 10px;
    margin-bottom: 30px;
    font-family: $font-manrope;

    h3 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.35px;
        margin: 0;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        span {
            color: rgba($white, 0.5);
            padding-left: 4px;
        }

        &:before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: $c-00d1ff;
            display: block;
            margin-right: 6px;
        }

        &.spendingCategoryTitle {
            margin: 19px 0 5px;
            color: $c-a4a9bf;

            &:before {
                display: none;
            }
        }
    }

    &Category {
        input[type="checkbox"] {
            & + label {
                border: 1px solid rgba($c-a4a9bf, 0.2);
                background-color: rgba($c-9396a5, 0.05);
                color: $c-a4a9bf;
                border-radius: 5px;
                padding: 4px 11px 3px 11px;
                font-size: 12px;
                transition: $transition-bg, $transition-border;
                margin-bottom: 6px;
                font-family: $font-manrope;
                font-weight: 500;

                &:before,
                &:after {
                    display: none;
                }

                &:hover {
                    background-color: rgba($c-00d1ff, 0.1);
                    border: 1px solid rgba($c-a4a9bf, 1);
                    transition: $transition-bg, $transition-border;
                }
            }

            &:checked {
                & + label {
                    background-color: $c-00d1ff;
                    color: $black;
                }
            }
        }
    }

    &Content {
        padding-left: 0;
        margin: 0;
        margin-left: -8px;

        &Row {
            list-style: none;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.35px;
            color: $c-a4a9bf;

            @for $i from 1 through 5 {
                &:nth-of-type(#{$i}) {
                    & .spendingContentCircles {
                        height: calc(27px + (#{$i}px) * 2);
                    }
                }
            }
        }

        &Circles {
            position: relative;
            width: 33px;
            height: 33px;
            margin-right: 4px;
        }
    }
}
