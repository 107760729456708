@import "../../styles/modules";

.socialButtons {
    display: flex;
    margin-right: 28px;
    position: relative;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
        transition: width 0.6s ease-in-out .7s;
        margin-right: 0;
        display: none;

        &:hover {
            width: 214px;
            transition: width 0.7s ease-in-out;

            button:not(.shareOpen) {
                opacity: 1;
                pointer-events: initial;
            }
        }
    }


    @include media-breakpoint-down(md) {
        &:after {
            content: "";
            height: calc(100% + 17px + 18px);
            width: 1px;
            background-color: rgba($white, 0.1);
            position: absolute;
            right: -10px;
            top: -17px;
        }

        .linkShareSuccess {
            text-align: right;
        }
    }

    .linkShareSuccess {
        background: transparent;
        color: $white;
        display: flex;
        align-items: center;
        margin-right: 6px;
        font-size: 14px;
        font-family: $font-clearsans;
    }

    .linkShareMobile {
        display: none;

        @include media-breakpoint-down(md) {
            background: transparent;
            color: $white;
            display: flex;
            font-size: 24px;
            font-family: $font-clearsans;
            position: absolute;
            right: 12px;
            top: 5px;
            transition: opacity 0.35s ease-in-out;
        }
    }

    a,
    button {
        margin-left: 6px;
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        outline: none;
        text-decoration: none;
        background-color: transparent;
        border: none;
        padding: 0px;

        @for $i from 1 through 5 {
            &:nth-child(#{5 - $i}) {
                transition-delay: calc(#{$i * 0.15}s);
            }
        }

        strong {
            background-color: rgba($color: $white, $alpha: 0.1);
            border-radius: 16px;
            font-size: 18px;
            line-height: 32px;
            color: $white;
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            opacity: 0.7;
            transition: opacity 0.35s ease-in-out;
        }
    }

    @include media-breakpoint-up(lg) {
        .shareOpen {
            opacity: 1;
        }
        button:not(.shareOpen) {
            opacity: 0;
            pointer-events: none;
        }
    }

    @include media-breakpoint-down(md) {
        a,
        button {
            opacity: 0;
            pointer-events: none;
            margin-left: 1vw;
            margin-right: 1vw;

            strong {
                width: #{"min(9vw, 32px)"};
                height: #{"min(9vw, 32px)"};
            }
        }
        &Revealed {
            .shareOpen {
                display: none;
            }

            a,
            button {
                opacity: 1;
                pointer-events: initial;
            }

            .linkShareMobile {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}





.shareDesk {
    position: relative;
    display: flex;
    margin-right: 28px;
    position: relative;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
        display: none;
    }

    & > button {
        margin-left: 6px;
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        outline: none;
        text-decoration: none;
        background-color: transparent;
        border: none;
        padding: 0px;
        background-color: $tr;

        &:hover {
            opacity: 0.7;
            transition: opacity 0.35s ease-in-out;
        }

        strong {
            background-color: rgba($color: $white, $alpha: 0.1);
            border-radius: 16px;
            font-size: 18px;
            line-height: 32px;
            color: $white;
            display: flex;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
        }
    }
}


.socialDropDown {
    position: absolute;
    top: 54px;
    right: 6px;
    background-color: rgba($color: $black, $alpha: 0.8);
    min-width: 180px;
    border-radius: 10px;
    padding: 8px 20px;
    display: none;

    &:after {
        content: "";
        display: 'block';
        height: 0;
        width: 0;
        right: 10px;
        top: -8px;
        position: absolute;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 8px solid rgba($color: $black, $alpha: 0.8);
    }

    ul {
        list-style: none;
        margin:0;
        padding-left: 0;
    }

    li {
        border-bottom: 1px solid rgba($color: $c-a4a9bf, $alpha: 0.1);

        &:last-child {
            border-bottom: 0 none;
        }
    }

    span{
        font-size: 18px;
        margin-right: 10px;
    }

    button,
    strong {
        opacity: 1;
        font-family: $font-manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 18px;
        letter-spacing: 0.35px;
        padding: 12px 0 !important;
        display: block;
        white-space: nowrap;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: $transition-color;

        &:hover {
            color: rgba($color: $white, $alpha: 0.8) !important;
            transition: $transition-color;
        }
    }
}

.socialDeskToggle{
    .socialDropDown {
        display: block;
    }
}
