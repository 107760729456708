@import '../../styles/modules';

.infoPopup {
    background-color: rgba($color: $c-1e252e, $alpha: .95);
    border-radius: 10px;
    padding: 0 15px;
    position: absolute;
    width: 400px;
    bottom: 50%;
    left: calc(50% - 200px);

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid rgba($color: $c-1e252e, $alpha: .95);
        left: calc(50% - 8px);
    }

    &Header {
        display: flex;
        border-bottom: 1px solid rgba($color: $white, $alpha: .2);
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        padding-bottom: 12px;

        .infoCountryName {
            font-family: $font-inter;
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            white-space: nowrap;
        }

        .infoActuality {
            margin-bottom: -2px;

            &Label{
                font-size: 10px;
                line-height: 14px;
                text-transform: uppercase;
                color: rgba($color: $white, $alpha: .75);
                display: block;
                margin-bottom: -2px;
            }
        }
    }

    &Body {
        padding-top: 14px;
        padding-bottom: 18px;
        display: flex;
    }

    &Footer {
        text-align: center;
        padding: 17px 0;
        font-family: "Clear Sans", sans-serif;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        border-top: 1px solid rgba($color: $white, $alpha: .2);
    }
}

.infoClose{
    background-color: $tr;
    padding: 0;
    width: 32px;
    height: 32px;
    border: 0 none;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    margin-right: -10px;

    &:focus {
        outline: none;
    }
}

.infoLeft {
    flex: 0 0 220px;
    max-width: 220px;
    position: relative;
    width: 100%;
    padding-right: 5px;
}

.infoRight {
    border-left: 2px solid rgba($color: $white, $alpha: .2);
    padding-left: 18px;
    width: 170px;
}

.title {
    font-family: $font-clearsans;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    text-transform: uppercase;
    color: rgba($color: $white, $alpha: .75);
    margin: 0 0 8px;
}

.listCircles {
    margin-bottom:0;
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;

    li {
        display: flex;
        justify-content: space-between;
        font-family: $font-clearsans;
        font-size: 12px;
        line-height: 16px;
        color: rgba($color: $white, $alpha: .8);
        padding-left: 15px;
        position: relative;
        margin-bottom: 10px;

        &:before {
            width: 10px;
            height: 10px;
            border: 2px solid $white;
            border-radius: 5px;
            content:'';
            top: 4px;
            left: 0;
            position:absolute;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .purple {
        &:before {
            border-color: $c-b02be3;
        }
    }

    .cyan {
        &:before {
            border-color: $c-00d1ff;
        }
    }

    .orange {
        &:before {
            border-color: $c-ff6e30;
        }
    }

    .malach {
        &:before {
            border-color: $c-18e770;
        }
    }

    .blue {
        &:before {
            border-color: $primary;
        }
    }
}

.infoColumns {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;

    div {
        position: relative;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 8px;
    }

    strong {
        display: block;
        font-family: $font-playfair;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
    }

    span{
        font-family: $font-clearsans;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: rgba($color: $white, $alpha: .75);
    }
}
