
input[type="radio"] {
    &:checked,
    &:not(:checked) {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            padding-left: 18px;
            cursor: pointer;
            line-height: 18px;
            display: inline-block;
            color: $c-a4a9bf;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                width: 12px;
                height: 12px;
                border: 1px solid $c-00d1ff;
                border-radius: 100%;
                background: transparent;
            }

            &:after {
                content: '';
                width: 8px;
                height: 8px;
                background: $c-00d1ff;
                position: absolute;
                top: 4px;
                left: 2px;
                border-radius: 100%;
                transition: all 0.2s ease;
            }
        }
    }

    &:not(:checked) {
        & + label {
            &:before {
                border-color: $c-a4a9bf;
            }
            &:after {
                opacity: 0;
                transform: scale(0);
            }
        }
    }

    &:checked {
        & + label {
            &:after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}